<template>
    <Popover controls-element="nearestLocationPanel"
             panel-label="Find Nearest Location"
             close-label="Close Nearest Location Panel">
        <template v-slot:button>
            <img src="/getmedia/6262e368-62ac-4c94-9aa1-f07581306031/a_icon_location.svg" alt="location marker on map" />&nbsp;&nbsp;
            <span><span>Nearest Convenient Care</span></span>
        </template>
        <template v-slot:default>
            <a href="/locations" ref="currentLocation" @click.stop.prevent="geocode">Use my Current Location</a>
            <form action="/Locations" id="enter-address-form" method="get">
                <label for="find-address">Or Enter Your Address</label>
                <div>
                    <input type="search" name="address" value="" ref="findAddress" class="m-search__field" aria-label="Enter your address">
                    <input type="hidden" name="service" value="Convenient Care and Convenient Care Plus">
                    <button type="submit" aria-label="Perform search" class="m-search__btn btn btn--green">Find</button>
                </div>
            </form>
        </template>
    </Popover>
</template>

<script type="text/javascript">
    import { getUserPosition } from '@/Scripts/util/helpers';
    import Google from '@/Widgets/GoogleMaps/google-maps-loader';
    import Popover from './Popover.vue';

    export default {
        name: 'FindLocation',
        components: {
            Popover
        },
        methods: {
            async geocode() {
                try {
                    const center = await getUserPosition();
                    const loader = new Google(window.mvcVars.config.GoogleMapsKey);
                    const google = await loader.load();
                    const geocoder = new google.maps.Geocoder;
                    geocoder.geocode({ 'location': center }, function (results) {
                        const srv = encodeURIComponent('Convenient Care and Convenient Care Plus');
                        if (results.length) {
                            const addr = encodeURIComponent('My Current Location');
                            window.location.href = `/locations?address=${addr}&service=${srv}`;
                        } else {
                            window.location.href = `/locations?service=${srv}`;
                        }
                    });
                } catch (err) {
                    alert('It appears you have Goelocation permissions turned off. Please update your browser settings and refresh the page, or enter your address below.');
                    this.$refs.findAddress.focus();
                }
            }
        },
    }
</script>