var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    { ref: "combobox" },
    _vm._l(_vm.source, function(item) {
      return _c("option", { key: item.id }, [_vm._v(_vm._s(item.title))])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }