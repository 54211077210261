<template>
    <div class="search">
        <apiresult :endpoint="source" @success="onLoad"></apiresult>
        <div class="search__results" v-if="searchResults.length">
            <slot :items="searchResults"></slot>
        </div>
        <div class="search__results--empty" v-else>
            <slot name="notFound">No results found</slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SearchResults',
        props: {
            source: String,
            terms: Object,
            matcher: Function,
        },
        data() {
            return {
                results: null,
            };
        },
        computed: {
            searchResults() {
                if (!this.results) {
                    return [];
                }
                return this.matchTerms();
            }
        },
        methods: {
            onLoad(results) {
                this.results = results;
            },
            matchTerms() {
                return this.results.filter((item) => {
                    return this.matcher ? this.matcher(item, this.terms) : this.defaultSearch(item, this.terms);
                });
            },
            defaultSearch(item, terms) {
                let isMatch = false;
                for (name in terms) {
                    if (terms[name] && (name in item)) {
                        isMatch = new RegExp(`${terms[name]}`, 'gi').test(item[name]);
                        if (isMatch) {
                            break;
                        }
                    }
                }
                return isMatch;
            },
        },
    };
</script>