<template>
    <li class="mail">
        <a :href="shareUrl">
            <i class="fa fa-envelope-square" aria-hidden="true"></i>
            <span class="show-for-sr">Link to share via email</span>
        </a>
    </li>
</template>

<script type="text/javascript">
    export default {
        name: 'Email',
        props: {
            subject: {
                type: String,
                required: true,
            },
        },
        computed: {
            shareUrl() {
                return 'mailto:?' + `subject=${encodeURIComponent(this.subject)}&body=${this.getBody()}`;
            },
        },
        methods: {
            getBody() {
                if (this.$slots.default.length) {
                    return encodeURIComponent(this.$slots.default[0].text.trim());
                }
                return '';
            },
        },
    };
</script>