var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-api" }, [
    _c(
      "div",
      { staticClass: "m-api__result" },
      [
        _vm._t("default", null, {
          result: _vm.result,
          displayname: _vm.displayname,
          noresult: _vm.noresult,
          loading: _vm.loading,
          error: _vm.error,
          letters: _vm.letters,
          letterClick: _vm.letterClick
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }