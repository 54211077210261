var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "o-single-provider",
      attrs: { itemscope: "", itemtype: "http://schema.org/Physician" }
    },
    [
      _vm._t("before", null, { provider: _vm.provider }),
      _vm._v(" "),
      _vm._t(
        "default",
        [
          _c("div", { staticClass: "grid-container m-provider" }, [
            _c("div", { staticClass: "grid-x align-center" }, [
              _c("div", { staticClass: "cell small-12 large-10" }, [
                _c(
                  "div",
                  { staticClass: "grid-x grid-padding-x provider-card" },
                  [
                    _c("div", { staticClass: "cell auto" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid-x grid-margin-x provider-card__profile"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "cell small-12 large-4 provider-card__image"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  itemprop: "image",
                                  src: _vm.image,
                                  alt: "Photo of " + _vm.fullName
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "cell small-12 large-8 provider-card__name"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "fullname",
                                  attrs: { itemprop: "name" }
                                },
                                [_vm._v(_vm._s(_vm.fullName))]
                              ),
                              _vm._v(" "),
                              _vm.provider.Specialties.length
                                ? _c(
                                    "p",
                                    _vm._l(_vm.provider.Specialties, function(
                                      specialty,
                                      i
                                    ) {
                                      return _c(
                                        "span",
                                        {
                                          attrs: {
                                            itemtype:
                                              "http://schema.org/MedicalSpecialty",
                                            itemscope: "",
                                            itemprop: "medicalSpecialty"
                                          }
                                        },
                                        [
                                          i !== 0 ? [_vm._v(",")] : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { attrs: { itemprop: "name" } },
                                            [_vm._v(_vm._s(specialty))]
                                          )
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.provider.VimeoId
                                ? _c("p", [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "a-btn-main a-btn--uppercase a-btn--video",
                                        attrs: {
                                          href:
                                            "https://player.vimeo.com/video/" +
                                            _vm.provider.VimeoId,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-play" }),
                                        _vm._v(
                                          " Watch Video\n                                        "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.sfcri
                                ? [
                                    _vm.ratings.valid &&
                                    _vm.ratings.profile.reviewcount > 0 &&
                                    _vm.provider.HasStars
                                      ? _c(
                                          "div",
                                          { staticClass: "m-star-rating" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "m-star-rating__anchor",
                                                attrs: {
                                                  href: "#comments",
                                                  itemtype:
                                                    "http://schema.org/AggregateRating",
                                                  itemscope: "",
                                                  itemprop: "aggregateRating"
                                                }
                                              },
                                              [
                                                _vm._l(5, function(n) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "m-star-rating__star"
                                                    },
                                                    [
                                                      n <=
                                                      Math.floor(
                                                        _vm.ratings.profile
                                                          .averageRatingStr
                                                      )
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fa fa-star"
                                                          })
                                                        : n <=
                                                          Math.ceil(
                                                            _vm.ratings.profile
                                                              .averageRatingStr
                                                          )
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fa fa-star-half-o"
                                                          })
                                                        : _c("i", {
                                                            staticClass:
                                                              "fa fa-star-o"
                                                          })
                                                    ]
                                                  )
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "m-star-rating__score"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          itemprop:
                                                            "ratingValue"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        " +
                                                            _vm._s(
                                                              _vm.ratings
                                                                .profile
                                                                .averageRatingStr
                                                            ) +
                                                            "\n                                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" out of 5 ("),
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          itemprop:
                                                            "ratingCount"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.ratings.profile
                                                              .reviewcount
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " Ratings, " +
                                                        _vm._s(
                                                          _vm.ratings.profile
                                                            .bodyCountStr
                                                        ) +
                                                        ")\n                                                "
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "m-star-rating" },
                                          [
                                            _vm._l(5, function(n) {
                                              return _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "m-star-rating__star"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-star-o"
                                                  })
                                                ]
                                              )
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "m-star-rating__score"
                                              },
                                              [_vm._v("Not yet rated")]
                                            )
                                          ],
                                          2
                                        )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.provider.Locations.length
                      ? _c("div", { staticClass: "cell large-4" }, [
                          _c(
                            "div",
                            {
                              staticClass: "grid-x provider-card__location",
                              attrs: {
                                itemtype: "http://schema.org/Hospital",
                                itemscope: "",
                                itemprop: "hospitalAffiliation"
                              }
                            },
                            [
                              _c("div", { staticClass: "cell small-12" }, [
                                _c("p", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/locations/" +
                                          _vm.provider.Locations[0].Slug
                                      }
                                    },
                                    [
                                      _c(
                                        "strong",
                                        { attrs: { itemprop: "name" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.provider.Locations[0].Title
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    " (Primary Location)\n                                    "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cell small-12" }, [
                                _c(
                                  "p",
                                  {
                                    attrs: {
                                      itemprop: "address",
                                      itemscope: "",
                                      itemtype:
                                        "http://schema.org/PostalAddress"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "address",
                                        attrs: { itemprop: "streetAddress" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.provider.Locations[0].Address1
                                            ) +
                                            "\n                                            "
                                        ),
                                        _vm.provider.Locations[0].Address2
                                          ? _c("br")
                                          : _vm._e(),
                                        _vm._v(
                                          _vm._s(
                                            _vm.provider.Locations[0].Address2
                                          ) +
                                            "\n                                        "
                                        )
                                      ]
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "cityState" }, [
                                      _c(
                                        "span",
                                        {
                                          attrs: { itemprop: "addressLocality" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.provider.Locations[0].City
                                            ) + ","
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          attrs: { itemprop: "addressRegion" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.provider.Locations[0].State
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { attrs: { itemprop: "postalCode" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.provider.Locations[0].Zip
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.provider.Locations[0].Phone
                                  ? _c("p", [
                                      _c("strong", [_vm._v("Main Office: ")]),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "tel:" +
                                              _vm.provider.Locations[0].Phone
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.provider.Locations[0].Phone
                                              ) +
                                              "\n                                        "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _vm.provider.Locations.length > 1
                                ? _c("div", { staticClass: "cell small-12" }, [
                                    _c(
                                      "p",
                                      [
                                        _c("strong", [
                                          _vm._v("Other Locations: ")
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.provider.Locations.length - 1,
                                          function(i) {
                                            return _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/locations/" +
                                                    _vm.provider.Locations[i]
                                                      .Slug
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.provider.Locations[i]
                                                        .Title
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "grid-x grid-padding-x provider-details" },
                  [
                    _vm.sfcri
                      ? [
                          _vm.provider.Fellowships.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Fellowships")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Fellowships, function(
                                      fellowship
                                    ) {
                                      return _c(
                                        "li",
                                        [
                                          fellowship.Focus
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(fellowship.Focus) +
                                                    ", "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          fellowship.Institute
                                            ? [
                                                fellowship.Institute.Title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          fellowship.Institute
                                                            .Title
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                fellowship.Institute.City
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          fellowship.Institute
                                                            .City
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                fellowship.Institute.State
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          fellowship.Institute
                                                            .State
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                fellowship.Institute.Country
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          fellowship.Institute
                                                            .Country
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(fellowship.Years))
                                          ])
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Residencies.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Residency")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Residencies, function(
                                      residency
                                    ) {
                                      return _c(
                                        "li",
                                        [
                                          residency.Focus
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(residency.Focus) + ", "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          residency.Institute
                                            ? [
                                                residency.Institute.Title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          residency.Institute
                                                            .Title
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                residency.Institute.City
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          residency.Institute
                                                            .City
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                residency.Institute.State
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          residency.Institute
                                                            .State
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                residency.Institute.Country
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          residency.Institute
                                                            .Country
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(residency.Years))
                                          ])
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Education.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Education")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Education, function(
                                      education
                                    ) {
                                      return _c(
                                        "li",
                                        [
                                          education.Focus
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(education.Focus) + ", "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          education.Institute
                                            ? [
                                                education.Institute.Title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          education.Institute
                                                            .Title
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                education.Institute.City
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          education.Institute
                                                            .City
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                education.Institute.State
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          education.Institute
                                                            .State
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                education.Institute.Country
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          education.Institute
                                                            .Country
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(education.Years))
                                          ])
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.ResearchInterests.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Research Interests")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(
                                      _vm.provider.ResearchInterests,
                                      function(interest) {
                                        return _c("li", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(interest) +
                                              "\n                                    "
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.ExternalLinks.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("External Resources")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.ExternalLinks, function(
                                      link
                                    ) {
                                      return _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "a-external-link",
                                            attrs: {
                                              href: link.Url,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(link.Title || link.Url)
                                            )
                                          ]
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Publications.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Publications")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Publications, function(
                                      publication
                                    ) {
                                      return _c("li", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(publication) +
                                            "\n                                    "
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      : [
                          _vm.provider.MedicalInterests.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Medical Interests")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(
                                      _vm.provider.MedicalInterests,
                                      function(interest) {
                                        return _c("li", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(interest) +
                                              "\n                                    "
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Fellowships.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Fellowships")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Fellowships, function(
                                      fellowship
                                    ) {
                                      return _c(
                                        "li",
                                        [
                                          fellowship.Focus
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(fellowship.Focus) +
                                                    ", "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          fellowship.Institute
                                            ? [
                                                fellowship.Institute.Title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          fellowship.Institute
                                                            .Title
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                fellowship.Institute.City
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          fellowship.Institute
                                                            .City
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                fellowship.Institute.State
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          fellowship.Institute
                                                            .State
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                fellowship.Institute.Country
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          fellowship.Institute
                                                            .Country
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(fellowship.Years))
                                          ])
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Residencies.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Residency")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Residencies, function(
                                      residency
                                    ) {
                                      return _c(
                                        "li",
                                        [
                                          residency.Focus
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(residency.Focus) + ", "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          residency.Institute
                                            ? [
                                                residency.Institute.Title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          residency.Institute
                                                            .Title
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                residency.Institute.City
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          residency.Institute
                                                            .City
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                residency.Institute.State
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          residency.Institute
                                                            .State
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                residency.Institute.Country
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          residency.Institute
                                                            .Country
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(residency.Years))
                                          ])
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Internships.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Internships")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Internships, function(
                                      internship
                                    ) {
                                      return _c(
                                        "li",
                                        [
                                          internship.Focus
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(internship.Focus) +
                                                    ", "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          internship.Institute
                                            ? [
                                                internship.Institute.Title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          internship.Institute
                                                            .Title
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                internship.Institute.City
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          internship.Institute
                                                            .City
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                internship.Institute.State
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          internship.Institute
                                                            .State
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                internship.Institute.Country
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          internship.Institute
                                                            .Country
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(internship.Years))
                                          ])
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Education.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Education")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Education, function(
                                      education
                                    ) {
                                      return _c(
                                        "li",
                                        [
                                          education.Focus
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(education.Focus) + ", "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          education.Institute
                                            ? [
                                                education.Institute.Title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          education.Institute
                                                            .Title
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                education.Institute.City
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          education.Institute
                                                            .City
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                education.Institute.State
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          education.Institute
                                                            .State
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                education.Institute.Country
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          education.Institute
                                                            .Country
                                                        ) + ", "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(education.Years))
                                          ])
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Certifications.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Certifications")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(
                                      _vm.provider.Certifications,
                                      function(certification) {
                                        return _c("li", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(certification) +
                                              "\n                                    "
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.provider.Affiliations.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "cell small-12 provider-detail"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "provider-detail__heading" },
                                    [_vm._v("Professional Societies")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "provider-detail__list" },
                                    _vm._l(_vm.provider.Affiliations, function(
                                      affiliation
                                    ) {
                                      return _c("li", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(affiliation) +
                                            "\n                                    "
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                  ],
                  2
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.ratings.valid && _vm.provider.HasStars && !_vm.sfcri
            ? [
                _c("span", { attrs: { id: "comments" } }),
                _vm._v(" "),
                _c("div", { staticClass: "grid-container m-reviews" }, [
                  _vm.ratings.reviews.length
                    ? _c(
                        "div",
                        { staticClass: "grid-x grid-padding-x align-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "small-12 large-10 cell" },
                            [
                              _c("h2", [_vm._v("Comments")]),
                              _vm._v(" "),
                              _vm._l(_vm.ratings.reviews, function(review) {
                                return _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-x grid-margin-x m-reviews__review"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "small-shrink medium-2 cell"
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.convertDate(
                                                review.formattedReviewDate
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "small-auto medium-10 cell"
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                review.bodyForDisplay
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(review.bodyForDisplay)
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            : _vm._e()
        ],
        { provider: _vm.provider }
      ),
      _vm._v(" "),
      _vm._t("after", null, { provider: _vm.provider })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }