<template>
    <div>
        <div v-if="loaded">
            <component :is="fieldType" :source="json" :placeholder="placeholder"></component>
        </div>
        <div v-else>
            <span v-if="error">There was a problem parsing a JSON object from {{ payload }}</span>
            <span v-else>Loading...</span>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import 'webpack-jquery-ui/css';
    import Autocomplete from './Autocomplete.vue';
    import Combobox from './Combobox.vue';

    export default {
        name: 'Datasource',
        components: {
            Autocomplete,
            Combobox,
        },
        props: {
            sourceType: String,
            fieldType: String,
            payload: String,
            placeholder: String,
        },
        data() {
            return {
                raw: this.payload,
                loaded: false,
                error: false,
            };
        },
        computed: {
            json() {
                return JSON.parse(this.raw);
            },
        },
        methods: {
            loadApi() {
                $.getJSON(this.payload)
                    .then((r) => {
                        this.raw = JSON.stringify(r);
                        this.loadUi();
                    })
                    .catch(() => {
                        this.error = true;
                    });
            },
            loadUi() {
                this.loaded = true;
            }
        },
        mounted() {
            switch (this.sourceType) {
                case 'JSON':
                    this.loadUi();
                    break;
                case 'API Endpoint':
                    this.loadApi();
                    break;
            }
        },
    };
</script>