<template>
    <input type="text" :placeholder="placeholder" ref="autocomplete" />
</template>

<script>
    import $ from 'jquery';
    import 'webpack-jquery-ui/autocomplete';

    export default {
        name: 'Autocomplete',
        props: {
            source: Array,
            placeholder: String,
        },
        mounted() {
            $(this.$refs.autocomplete).autocomplete({
                minLength: 0,
                source: this.source.map(item => item.title),
            });
        }
    };
</script>