<template>
    <div class="m-api">
        <div class="m-api__result">
            <slot :result="result" :displayname="displayname" :noresult="noresult" :loading="loading" :error="error"></slot>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    export default {
        name: 'ListView',
        props: {
            endpoint: String,
            pagetype: String,
            pagetypedisplayname: String,
            auth: String,
        },
        data() {
            return {
                loading: false,
                displayname: this.pagetypedisplayname,
                result: null,
                error: null,
                noresult: null,
            };
        },
        mounted() {
            var vm = this;
            let query = "&orderby=DocumentName ASC";

            if (vm.pagetypedisplayname == "Services") {
                query = "&Where=ExcludeFromList IS NULL OR ExcludeFromList = 0&orderby=DocumentName ASC";
            }

            $.ajax({
                url: vm.endpoint + vm.pagetype + query,
                type: 'GET',
                dataType: 'json',
                beforeSend: (xhr) => {
                    xhr.setRequestHeader('Authorization', 'Basic ' + this.auth);
                    vm.loading = true;
                    vm.noresult = false;
                },
                success: (r) => {
                    vm.$emit('success', r);
                    vm.result = r;
                    if (r.cms_documents[0].carle_service == undefined && r.cms_documents[0].carle_condition == undefined) {
                        vm.noresult = true;
                    }

                    return vm.data;
                },
                error: (e) => {
                    vm.$emit('error', e);
                    vm.error = e;
                }

            }).done(function () {
                vm.loading = false;
            });
        },
    };
</script>