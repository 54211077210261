var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabbed-menu-wrap grid-container" }, [
    _c("div", { staticClass: "grid-x" }, [
      _c(
        "div",
        { staticClass: "cell large-12 tab-button-wrap" },
        _vm._l(_vm.tabs, function(text, name) {
          return _c(
            "button",
            {
              staticClass: "tab-button",
              class: { active: name === _vm.selected },
              on: {
                click: function($event) {
                  return _vm.handleClick(name)
                }
              }
            },
            [_vm._v("\n                " + _vm._s(text) + "\n            ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cell large-10 large-offset-1 tabbed-menu" },
        [
          _vm.selected
            ? _vm._t("default", null, { selected: _vm.selected })
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }