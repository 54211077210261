<template>
    <li class="linkedin">
        <a href="#" @click.prevent="openUrl">
            <i class="fa fa-linkedin-square" aria-hidden="true"></i>
            <span class="show-for-sr">Link to share via LinkedIn</span>
        </a>
    </li>
</template>

<script type="text/javascript">
    export default {
        name: 'LinkedIn',
        props: {
            url: {
                type: String,
                default: window.location.href
            },
        },
        computed: {
            shareUrl() {
                return `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.url)}`;
            }
        },
        methods: {
            openUrl(e) {
                this.$parent.$emit('open', this.shareUrl, 'linkedin');
            },
        },
    };
</script>