var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-container" }, [
    _c(
      "form",
      {
        ref: "form",
        staticClass: "grid-x grid-margin-y",
        attrs: { novalidate: "" },
        on: { submit: _vm.send }
      },
      [
        _c("div", { staticClass: "small-12 cell" }, [
          _c("fieldset", { attrs: { "aria-required": "" } }, [
            _c("legend", [
              _vm._v("Are you recognizing an individual or group?")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-x grid-margin-x" }, [
              _c(
                "div",
                {
                  staticClass:
                    "shrink cell radio-checkbox radio-checkbox--circle"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.RecognitionType,
                        expression: "data.RecognitionType"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      name: "RecognitionType",
                      id: "RecognitionType-individual-radio",
                      required: ""
                    },
                    domProps: {
                      value: 4,
                      checked: _vm._q(_vm.data.RecognitionType, 4)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.data, "RecognitionType", 4)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "RecognitionType-individual-radio" } },
                    [_vm._v("Individual")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "shrink cell radio-checkbox radio-checkbox--circle"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.RecognitionType,
                        expression: "data.RecognitionType"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      name: "RecognitionType",
                      id: "RecognitionType-group-radio",
                      required: ""
                    },
                    domProps: {
                      value: 5,
                      checked: _vm._q(_vm.data.RecognitionType, 5)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.data, "RecognitionType", 5)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "RecognitionType-group-radio" } },
                    [_vm._v("Group")]
                  )
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.data.RecognitionType !== null
          ? [
              _c(
                "div",
                { staticClass: "small-12 cell" },
                [
                  _vm.data.RecognitionType === 4
                    ? _c("fieldset", [
                        _c("legend", [_vm._v("Who are you recognizing?")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-x grid-margin-x" }, [
                          _c("div", { staticClass: "small-12 medium-6 cell" }, [
                            _c(
                              "label",
                              { attrs: { for: "rec-FirstName-input" } },
                              [_vm._v("First Name")]
                            ),
                            _vm._v(" "),
                            _vm.errors.Receiver.FirstName
                              ? _c("small", { staticClass: "a-input-error" }, [
                                  _vm._v(
                                    "\n                                Individual First Name is required\n                            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.Receiver.FirstName,
                                  expression: "data.Receiver.FirstName"
                                }
                              ],
                              staticClass: "m-search__field",
                              attrs: {
                                type: "text",
                                name: "Receiver[FirstName]",
                                id: "rec-FirstName-input",
                                "aria-required": ""
                              },
                              domProps: { value: _vm.data.Receiver.FirstName },
                              on: {
                                blur: function($event) {
                                  _vm.errors.Receiver.FirstName = false
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.data.Receiver,
                                    "FirstName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "small-12 medium-6 cell" }, [
                            _c(
                              "label",
                              { attrs: { for: "rec-LastName-input" } },
                              [_vm._v("Last Name")]
                            ),
                            _vm._v(" "),
                            _vm.errors.Receiver.LastName
                              ? _c("small", { staticClass: "a-input-error" }, [
                                  _vm._v(
                                    "\n                                Individual Last Name is required\n                            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.Receiver.LastName,
                                  expression: "data.Receiver.LastName"
                                }
                              ],
                              staticClass: "m-search__field",
                              attrs: {
                                type: "text",
                                name: "Receiver[LastName]",
                                id: "rec-LastName-input",
                                "aria-required": ""
                              },
                              domProps: { value: _vm.data.Receiver.LastName },
                              on: {
                                blur: function($event) {
                                  _vm.errors.Receiver.LastName = false
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.data.Receiver,
                                    "LastName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.RecognitionType === 5
                    ? [
                        _c("label", { attrs: { for: "rec-dept-input" } }, [
                          _vm._v(
                            "What is the name of the Group - department, unit, or floor - you want to recognize?"
                          )
                        ]),
                        _vm._v(" "),
                        _vm.errors.Receiver.Department
                          ? _c("small", { staticClass: "a-input-error" }, [
                              _vm._v(
                                "\n                        Group Department is required\n                    "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.Receiver.Department,
                              expression: "data.Receiver.Department"
                            }
                          ],
                          staticClass: "m-search__field",
                          attrs: {
                            type: "text",
                            name: "Receiver[Department]",
                            id: "rec-dept-input",
                            "aria-required": ""
                          },
                          domProps: { value: _vm.data.Receiver.Department },
                          on: {
                            blur: function($event) {
                              _vm.errors.Receiver.Department = false
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data.Receiver,
                                "Department",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "small-12 cell" }, [
                _c("fieldset", { attrs: { "aria-required": "" } }, [
                  _c("legend", [
                    _vm._v(
                      "Are you recognizing " +
                        _vm._s(
                          _vm.data.RecognitionType === 4
                            ? "an individual"
                            : "a group"
                        ) +
                        " from..."
                    )
                  ]),
                  _vm._v(" "),
                  _vm.errors.Receiver.EntityType
                    ? _c("small", { staticClass: "a-input-error" }, [
                        _vm._v(
                          "\n                        Please select the " +
                            _vm._s(
                              _vm.data.RecognitionType === 4
                                ? "Individual"
                                : "Group"
                            ) +
                            "'s location\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid-x grid-margin-x" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "small-12 cell radio-checkbox radio-checkbox--circle"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.Receiver.EntityType,
                              expression: "data.Receiver.EntityType"
                            }
                          ],
                          attrs: {
                            id: "EntityType-carle-radio",
                            name: "Receiver[EntityType]",
                            type: "radio",
                            required: ""
                          },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.data.Receiver.EntityType, 1)
                          },
                          on: {
                            blur: function($event) {
                              _vm.errors.Receiver.EntityType = false
                            },
                            change: function($event) {
                              return _vm.$set(
                                _vm.data.Receiver,
                                "EntityType",
                                1
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "EntityType-carle-radio" } },
                          [_vm._v("Carle")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "small-12 cell radio-checkbox radio-checkbox--circle"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.Receiver.EntityType,
                              expression: "data.Receiver.EntityType"
                            }
                          ],
                          attrs: {
                            id: "EntityType-healthAlliance-radio",
                            name: "Receiver[EntityType]",
                            type: "radio",
                            required: ""
                          },
                          domProps: {
                            value: 2,
                            checked: _vm._q(_vm.data.Receiver.EntityType, 2)
                          },
                          on: {
                            blur: function($event) {
                              _vm.errors.Receiver.EntityType = false
                            },
                            change: function($event) {
                              return _vm.$set(
                                _vm.data.Receiver,
                                "EntityType",
                                2
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "EntityType-healthAlliance-radio" } },
                          [_vm._v("Health Alliance")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "small-12 cell radio-checkbox radio-checkbox--circle"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.Receiver.EntityType,
                              expression: "data.Receiver.EntityType"
                            }
                          ],
                          attrs: {
                            id: "EntityType-stratumMed-radio",
                            name: "Receiver[EntityType]",
                            type: "radio",
                            required: ""
                          },
                          domProps: {
                            value: 3,
                            checked: _vm._q(_vm.data.Receiver.EntityType, 3)
                          },
                          on: {
                            blur: function($event) {
                              _vm.errors.Receiver.EntityType = false
                            },
                            change: function($event) {
                              return _vm.$set(
                                _vm.data.Receiver,
                                "EntityType",
                                3
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "EntityType-stratumMed-radio" } },
                          [_vm._v("StratumMed")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "small-12 cell radio-checkbox radio-checkbox--circle"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.Receiver.EntityType,
                              expression: "data.Receiver.EntityType"
                            }
                          ],
                          attrs: {
                            id: "EntityType-carleHoopeston-radio",
                            name: "Receiver[EntityType]",
                            type: "radio",
                            required: ""
                          },
                          domProps: {
                            value: 4,
                            checked: _vm._q(_vm.data.Receiver.EntityType, 4)
                          },
                          on: {
                            blur: function($event) {
                              _vm.errors.Receiver.EntityType = false
                            },
                            change: function($event) {
                              return _vm.$set(
                                _vm.data.Receiver,
                                "EntityType",
                                4
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "EntityType-carleHoopeston-radio" } },
                          [_vm._v("Carle Hoopeston")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "small-12 cell radio-checkbox radio-checkbox--circle"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.Receiver.EntityType,
                              expression: "data.Receiver.EntityType"
                            }
                          ],
                          attrs: {
                            id: "EntityType-dunno-radio",
                            name: "Receiver[EntityType]",
                            type: "radio",
                            required: ""
                          },
                          domProps: {
                            value: 5,
                            checked: _vm._q(_vm.data.Receiver.EntityType, 5)
                          },
                          on: {
                            blur: function($event) {
                              _vm.errors.Receiver.EntityType = false
                            },
                            change: function($event) {
                              return _vm.$set(
                                _vm.data.Receiver,
                                "EntityType",
                                5
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "EntityType-dunno-radio" } },
                          [_vm._v("Do Not Know")]
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.data.RecognitionType === 4
                ? _c("div", { staticClass: "small-12 cell" }, [
                    _c("fieldset", { attrs: { "aria-required": "" } }, [
                      _c("legend", [_vm._v("What does the Individual do?")]),
                      _vm._v(" "),
                      _vm.errors.Receiver.JobType
                        ? _c("small", { staticClass: "a-input-error" }, [
                            _vm._v(
                              "\n                        Please select the Individual's Job Type'\n                    "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "grid-x grid-margin-x" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "shrink cell radio-checkbox radio-checkbox--circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.Receiver.JobType,
                                  expression: "data.Receiver.JobType"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "Receiver[JobType]",
                                id: "Receiver-doctor-radio",
                                required: ""
                              },
                              domProps: {
                                value: 1,
                                checked: _vm._q(_vm.data.Receiver.JobType, 1)
                              },
                              on: {
                                blur: function($event) {
                                  _vm.errors.Receiver.JobType = false
                                },
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.data.Receiver,
                                    "JobType",
                                    1
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "Receiver-doctor-radio" } },
                              [_vm._v("Doctor")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shrink cell radio-checkbox radio-checkbox--circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.Receiver.JobType,
                                  expression: "data.Receiver.JobType"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "Receiver[JobType]",
                                id: "Receiver-nurse-radio",
                                required: ""
                              },
                              domProps: {
                                value: 2,
                                checked: _vm._q(_vm.data.Receiver.JobType, 2)
                              },
                              on: {
                                blur: function($event) {
                                  _vm.errors.Receiver.JobType = false
                                },
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.data.Receiver,
                                    "JobType",
                                    2
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "Receiver-nurse-radio" } },
                              [_vm._v("Nurse")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shrink cell radio-checkbox radio-checkbox--circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.Receiver.JobType,
                                  expression: "data.Receiver.JobType"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "Receiver[JobType]",
                                id: "Receiver-other-radio",
                                required: ""
                              },
                              domProps: {
                                value: 3,
                                checked: _vm._q(_vm.data.Receiver.JobType, 3)
                              },
                              on: {
                                blur: function($event) {
                                  _vm.errors.Receiver.JobType = false
                                },
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.data.Receiver,
                                    "JobType",
                                    3
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "Receiver-other-radio" } },
                              [_vm._v("Other")]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "small-12 cell" }, [
                _c("fieldset", [
                  _c("legend", [_vm._v("What is your name?")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid-x grid-margin-x" }, [
                    _c("div", { staticClass: "small-12 medium-6 cell" }, [
                      _c("label", { attrs: { for: "req-FirstName-input" } }, [
                        _vm._v("First Name")
                      ]),
                      _vm._v(" "),
                      _vm.errors.Requestor.FirstName
                        ? _c("small", { staticClass: "a-input-error" }, [
                            _vm._v(
                              "\n                                Please tell us your First Name\n                            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.Requestor.FirstName,
                            expression: "data.Requestor.FirstName"
                          }
                        ],
                        staticClass: "m-search__field",
                        attrs: {
                          type: "text",
                          name: "Requestor[FirstName]",
                          id: "req-FirstName-input",
                          "aria-required": ""
                        },
                        domProps: { value: _vm.data.Requestor.FirstName },
                        on: {
                          blur: function($event) {
                            _vm.errors.Requestor.FirstName = false
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data.Requestor,
                              "FirstName",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "small-12 medium-6 cell" }, [
                      _c("label", { attrs: { for: "req-LastName-input" } }, [
                        _vm._v("Last Name")
                      ]),
                      _vm._v(" "),
                      _vm.errors.Requestor.LastName
                        ? _c("small", { staticClass: "a-input-error" }, [
                            _vm._v(
                              "\n                                Please tell us your Last Name\n                            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.Requestor.LastName,
                            expression: "data.Requestor.LastName"
                          }
                        ],
                        staticClass: "m-search__field",
                        attrs: {
                          type: "text",
                          name: "Requestor[LastName]",
                          id: "req-LastName-input",
                          "aria-required": ""
                        },
                        domProps: { value: _vm.data.Requestor.LastName },
                        on: {
                          blur: function($event) {
                            _vm.errors.Requestor.LastName = false
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data.Requestor,
                              "LastName",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "small-12 cell" }, [
                _c("label", { attrs: { for: "req-Email-input" } }, [
                  _vm._v("What is your email? (optional)")
                ]),
                _vm._v(" "),
                _vm.errors.Requestor.Email
                  ? _c("small", { staticClass: "a-input-error" }, [
                      _vm._v(
                        "\n                    Please enter a valid email\n                "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.Requestor.Email,
                      expression: "data.Requestor.Email"
                    }
                  ],
                  staticClass: "m-search__field",
                  attrs: {
                    type: "text",
                    name: "Requestor[Email]",
                    id: "req-Email-input"
                  },
                  domProps: { value: _vm.data.Requestor.Email },
                  on: {
                    blur: function($event) {
                      _vm.errors.Requestor.Email = false
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.data.Requestor, "Email", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "small-12 cell" }, [
                _c("label", { attrs: { for: "req-Phone-input" } }, [
                  _vm._v("What is your phone number? (optional)")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.Requestor.Phone,
                      expression: "data.Requestor.Phone"
                    }
                  ],
                  staticClass: "m-search__field",
                  attrs: {
                    type: "text",
                    name: "Requestor[Phone]",
                    id: "req-Phone-input"
                  },
                  domProps: { value: _vm.data.Requestor.Phone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.data.Requestor, "Phone", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "small-12 cell" }, [
                _c("label", { attrs: { for: "msg-Story-text" } }, [
                  _vm._v(
                    "Tell us your Story. Describe your interaction with the employee and what made it special."
                  )
                ]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "Characters Remaining: " +
                      _vm._s(_vm.charLimit - _vm.data.Message.Story.length)
                  )
                ]),
                _vm._v(" "),
                _vm.errors.Message.Story
                  ? _c("small", { staticClass: "a-input-error" }, [
                      _c("br"),
                      _vm._v("Please tell us your Story\n                ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.Message.Story,
                      expression: "data.Message.Story"
                    }
                  ],
                  attrs: {
                    name: "Message[Story]",
                    id: "msg-Story-text",
                    "aria-required": ""
                  },
                  domProps: { value: _vm.data.Message.Story },
                  on: {
                    keypress: function($event) {
                      return _vm.charCheck($event, _vm.data.Message.Story)
                    },
                    blur: function($event) {
                      _vm.errors.Message.Story = false
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.data.Message, "Story", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "small-12 cell" }, [
                _c("label", { attrs: { for: "msg-Applause-text" } }, [
                  _vm._v(
                    "Optional - Please provide any additional detail about your visit to help us identify the individual (Department/Unit, date of visit, room number, etc)."
                  )
                ]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "Characters Remaining: " +
                      _vm._s(_vm.charLimit - _vm.data.Message.Applause.length)
                  )
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.Message.Applause,
                      expression: "data.Message.Applause"
                    }
                  ],
                  attrs: { name: "Message[Applause]", id: "msg-Applause-text" },
                  domProps: { value: _vm.data.Message.Applause },
                  on: {
                    keypress: function($event) {
                      return _vm.charCheck($event, _vm.data.Message.Applause)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.data.Message,
                        "Applause",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "small-12 cell checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.Requestor.MayContact,
                      expression: "data.Requestor.MayContact"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    name: "Requestor[MayContact]",
                    id: "req-MayContact-check"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.data.Requestor.MayContact)
                      ? _vm._i(_vm.data.Requestor.MayContact, null) > -1
                      : _vm.data.Requestor.MayContact
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.data.Requestor.MayContact,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.data.Requestor,
                              "MayContact",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.data.Requestor,
                              "MayContact",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.data.Requestor, "MayContact", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "req-MayContact-check" } }, [
                  _vm._v(
                    "(OPTIONAL) I give Carle permission to contact me to discuss the possibility of sharing my Story with the public."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "small-12 cell" }, [
                _c(
                  "button",
                  {
                    staticClass: "a-btn-main",
                    class: { "a-btn--loading": _vm.sending }
                  },
                  [_vm._v("Submit")]
                )
              ]),
              _vm._v(" "),
              _vm.hasErrors
                ? _c("div", { staticClass: "small-12 cell" }, [
                    _c("small", { staticClass: "a-input-error" }, [
                      _vm._v(
                        "Your form has some errors. Please review your information and try again."
                      )
                    ])
                  ])
                : _vm._e()
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("input", { attrs: { type: "hidden", name: "Id", value: "1" } }),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { ref: "success", staticClass: "grid-x success-msg" }, [
      _vm._v("\n        Thank you for your input!\n    ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }