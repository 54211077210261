var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loaded
      ? _c(
          "div",
          [
            _c(_vm.fieldType, {
              tag: "component",
              attrs: { source: _vm.json, placeholder: _vm.placeholder }
            })
          ],
          1
        )
      : _c("div", [
          _vm.error
            ? _c("span", [
                _vm._v(
                  "There was a problem parsing a JSON object from " +
                    _vm._s(_vm.payload)
                )
              ])
            : _c("span", [_vm._v("Loading...")])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }