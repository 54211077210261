var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-popover",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "button",
        {
          ref: "control",
          staticClass: "a-open-panel",
          attrs: {
            "aria-haspopup": "dialog",
            "aria-controls": _vm.controlsElement
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggle($event)
            }
          }
        },
        [_vm._t("button")],
        2
      ),
      _vm._v(" "),
      _c(
        "FocusTrap",
        {
          model: {
            value: _vm.trapped,
            callback: function($$v) {
              _vm.trapped = $$v
            },
            expression: "trapped"
          }
        },
        [
          _c(
            "div",
            {
              ref: "panel",
              staticClass: "a-link-panel",
              attrs: {
                role: "dialog",
                "aria-modal": "true",
                tabindex: "-1",
                "aria-label": _vm.panelLabel,
                id: _vm.controlsElement
              },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  return _vm.close($event)
                }
              }
            },
            [
              _c("div", { staticClass: "a-link-panel__inner" }, [
                _c("div", { staticClass: "grid-x" }, [
                  _c("div", { staticClass: "small-12 cell" }, [
                    _c("div", { staticClass: "grid-x align-middle" }, [
                      _c(
                        "div",
                        { staticClass: "cell auto" },
                        [
                          _vm._t("heading", [
                            _c("em", [_vm._v(_vm._s(_vm.panelLabel))])
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "cell shrink" }, [
                        _c(
                          "button",
                          {
                            staticClass: "a-link-panel__close",
                            attrs: { "aria-label": _vm.closeLabel },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.close($event)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-close" })]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "small-12 cell" },
                    [_vm._t("default")],
                    2
                  )
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }