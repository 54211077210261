<template>
    <div class="o-single-location">
        <slot name="before" v-bind:location="location"></slot>
        <slot v-bind:location="location" v-bind:directions="directions">
            <div class="grid-container grid-container-small">
                <div class="grid-x align-center">
                    <div class="small-12 cell m-location">
                        <div class="grid-x">
                            <div class="cell small-12 small-order-2 medium-auto large-order-1 m-location__result">
                                <div class="result-body">
                                    <h1 class="location-title">{{ location.Title }}</h1>
                                    <p class="location-notes" v-if="location.Notes">{{ location.Notes }}</p>
                                    <p class="location-tag" :class="`location-tag--${tag.toLowerCase()}`">
                                        {{ tag }}
                                    </p>
                                    <p class="location-info">
                                        <span class="address-1">{{ location.Address1 }}</span>
                                        <span class="address-2" v-if="location.Address2">{{ location.Address2 }}</span>
                                        <span class="city"> - {{ location.City }}, </span>
                                        <span class="state">{{ location.State }}</span>
                                        <span class="zip">{{ location.Zip }}</span>
                                    </p>
                                    <p class="location-phone" v-if="location.Phone">
                                        {{ location.Phone }}
                                    </p>
                                </div>
                                <div class="result-cta grid-x grid-margin-x grid-margin-y align-middle">

                                    <div class="small-12 medium-shrink cell result-cta__directions">
                                        <a :href="directions"
                                           target="_blank"
                                           class="a-btn-main a-btn--uppercase a-external-link"
                                           :aria-label="`Get directions to ${location.Title} (opens in new window)`">Get Directions</a>
                                    </div>
                                    <div class="small-6 medium-shrink cell result-cta__doctors" v-if="location.HasProviders">
                                        <a :href="search">See Doctors</a>
                                    </div>
                                    <div class="small-6 medium-shrink cell result-cta__campus" v-if="map">
                                        <a :href="map" target="_blank">Campus Map</a>
                                    </div>
                                </div>
                            </div>
                            <div class="cell small-12 small-order-1 medium-shrink large-order-2 m-location__image"
                                 :style="{ 'background-image': `url(${image})` }"
                                 v-if="location.PhotoId"></div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
        <slot name="alert" v-bind:location="location"></slot>
        <slot name="after" v-bind:location="location"></slot>
        <script type="application/ld+json">
            {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "{{ location.Title }}",
            "location": {
            "@type": "Place",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "{{ location.Address1 }}"
            "addressLocality": "{{ location.City }}",
            "addressRegion": "{{ location.State }}",
            "postalCode": "{{ location.Zip }}"
            },
            "url": "/locations/{{ location.Slug }}"
            }
            }
        </script>
    </div>
</template>

<script type="text/javascript">
    const center = { lat: 40.1164, lng: -88.2434 };
    export default {
        name: 'Location',
        props: {
            location: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                maps: {
                    13: 'https://carlesystems.martinonesource.com/images/234/00-1899/365.pdf',
                    74: 'https://carlesystems.martinonesource.com/images/234/00-1899/365.pdf',
                    75: 'https://carlesystems.martinonesource.com/images/234/00-1899/X2367.pdf',
                    4569: 'https://carlesystems.martinonesource.com/images/234/00-1899/365.pdf',
                },
            };
        },
        computed: {
            image() {
                return `${window.mvcVars.config.FapUrl}/Media/Location/${this.location.Id}?notfound=Location.svg`;
            },
            search() {
                return `/Find-a-Doctor/results?location=${encodeURIComponent(this.location.Title)}`;
            },
            directions() {
                const destination = `${this.location.Latitude},${this.location.Longitude}`;
                return `https://google.com/maps/dir/?api=1&destination=${destination}`;
            },
            map() {
                return this.maps[this.location.Id] || false;
            },
            tag() {
                return this.location.Designations.indexOf('Inpatient') !== -1 ? 'Inpatient' : 'Outpatient';
            }
        },
        mounted() {
            document.title = `${this.location.Title} | Carle.org`;
        }
    }
</script>