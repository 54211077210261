<template>
    <div class="m-api">
        <div class="m-api__loading" v-if="displayLoad && loading">
            <slot name="loading">Loading...</slot>
        </div>
        <div class="m-api__result" v-if="result">
            <slot :result="result"></slot>
        </div>
        <div class="m-api__error" v-if="displayError && error">
            <slot name="error" :error="error">An error has occurred</slot>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    export default {
        name: 'ApiResult',
        props: {
            endpoint: String,
            auth: String,
            payload: {
                type: Object,
                default: () => ({}),
            },
            method: {
                type: String,
                default: 'GET',
            },
            dataType: {
                type: String,
                default: 'json',
            },
            displayLoad: {
                type: Boolean,
                default: true,
            },
            displayError: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                loading: true,
                result: null,
                error: null,
            };
        },
        async mounted() {
            try {
                const headers = {};
                if (this.auth) {
                    headers['Authorization'] = `Bearer ${this.auth}`;
                }
                const r = await $.ajax({
                    url: this.endpoint,
                    data: this.payload,
                    method: this.method,
                    dataType: this.dataType,
                    headers,
                });
                this.$emit('success', r);
                this.result = r;
            } catch (e) {
                this.$emit('error', e)
                this.error = e;
            }
            this.loading = false;
        }
    };
</script>