var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c("apiresult", {
        attrs: { endpoint: _vm.source },
        on: { success: _vm.onLoad }
      }),
      _vm._v(" "),
      _vm.searchResults.length
        ? _c(
            "div",
            { staticClass: "search__results" },
            [_vm._t("default", null, { items: _vm.searchResults })],
            2
          )
        : _c(
            "div",
            { staticClass: "search__results--empty" },
            [_vm._t("notFound", [_vm._v("No results found")])],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }