<template>
    <div class="o-locations-index">
        <section class="grid-x grid-padding-x grid-padding-y hide-for-large"
                 aria-label="Switch between List and Map views"
                 :class="{ 'hide': enableControls }">
            <div class="cell small-12 toggle-controls">
                <div class="grid-x">
                    <div class="cell small-6 show-controls">
                        <button class="a-btn a-btn-main a-btn--uppercase toggle-controls__control" @click.prevent="enableControls = !enableControls">
                            Filter Location
                        </button>
                    </div>
                    <div class="cell small-6 toggle-map-display-button show-map">
                        <button class="a-btn a-btn--uppercase toggle-controls__control" @click.prevent="listView = !listView">
                            {{ listView ? 'Map View' : 'List View' }}
                        </button>
                    </div>
                </div>
            </div>
        </section>
        <div class="grid-y large-grid-frame">
            <div class="cell shrink map-controls__back hide-for-large"
                 :class="{ 'hide': !enableControls }">
                <div class="grid-x grid-padding-x grid-padding-y">
                    <div class="cell small-12">
                        <i class="fa fa-chevron-left"></i>
                        <a href="#" @click.prevent="enableControls = false">Go Back</a>
                    </div>
                </div>
            </div>
            <section class="cell shrink map-controls"
                     aria-label="Search and Filter Locations"
                     :class="{ 'show-for-large': !enableControls, 'map-controls--enabled': enableControls }">
                <div class="map-controls__container">
                    <form class="grid-x grid-padding-x grid-margin-y align-bottom" @submit.prevent="updateAddress">
                        <div class="cell small-12 large-auto" id="geocode-location">
                            <label for="user-location" class="map-controls__label">My Location:</label>
                            <div class="m-icon-field map-controls__control">
                                <img src="/Build/Images/Locations/a-location.svg" class="m-icon-field__icon" alt="">
                                <input type="text" id="user-location" ref="address" :value="query.address">
                            </div>
                        </div>

                        <div class="cell small-12 large-auto">
                            <label for="category-filter" class="map-controls__label">Type of Care:</label>
                            <div class="m-icon-field a-select map-controls__control">
                                <img src="/Build/Images/Locations/a-heart.svg" class="m-icon-field__icon" alt="">

                                <select class="gtm-type-of-care" id="category-filter"

                                        ref="category">
                                    <template v-if="categories.length">
                                        <option value="" :selected="!query.category">All Types</option>
                                        <option v-for="c in categories" :selected="c.toLowerCase() === query.category.toLowerCase()">{{ c }}</option>
                                    </template>
                                    <option disabled selected v-else>Loading...</option>
                                </select>
                            </div>
                        </div>

                        <div class="cell small-12 large-auto">
                            <label for="service-filter" class="map-controls__label">Service I Need:</label>
                            <div class="m-icon-field a-select map-controls__control">
                                <img src="/Build/Images/Locations/a-specialty.svg" class="m-icon-field__icon" alt="">
                                <select class="gtm-service-needed" id="service-filter"

                                        ref="service">
                                    <template v-if="services.length">
                                        <option value="" :selected="!query.service">All Services</option>
                                        <option v-for="s in services" :selected="s.toLowerCase() === query.service.toLowerCase()">{{ s }}</option>
                                    </template>
                                    <option disabled selected v-else>Loading...</option>
                                </select>
                            </div>

                        </div>
                        <div class="cell small-12 large-shrink">
                            <button type="submit" class="gtm-location-search a-btn a-btn-main a-btn--uppercase map-controls__control">
                                Submit
                            </button>
                        </div>
                    </form>

                </div>
            </section>
            <div class="cell large-auto large-cell-block-container locations-map"
                 :class="{ 'locations-map--list': listView, 'locations-map--map': !listView }">
                <div class="grid-x">
                    <section class="cell large-6 locations-container features-container large-cell-block-y"
                             :class="{ 'show-for-large': !listView }"
                             aria-label="List of location results sorted by distance">
                        <ol class="feature-list"
                            tabindex="-1"
                            ref="features"
                            v-if="features.length && loaded">

                            <li class="feature-list__feature feature-list__feature--no-results"
                                tabindex="-1"
                                v-if="!filteredFeatures.length || !queryIsValid">
                                <div class="query-not-found">
                                    Your search for
                                    <template v-if="getErrorField('category')">
                                        a(n) <strong>{{ getErrorField('category') }}</strong> location
                                    </template>
                                    <template v-else>
                                        locations
                                    </template>
                                    <template v-if="getErrorField('service')">
                                        with <strong>{{ getErrorField('service') }}</strong> services
                                    </template>
                                    returned zero results. Please try another search.
                                </div>
                            </li>

                            <li class="feature-list__feature"
                                tabindex="-1"
                                v-for="feature in filteredFeatures">
                                <div class="grid-x grid-padding-x">
                                    <div class="cell shrink inner__photo"
                                         :style="{ 'background-image': 'url('+ getPhoto(feature) +')' }"
                                         :class="{
                                            'inner__photo--content': !!feature.getProperty('PhotoId'),
                                            'inner__photo--default': !feature.getProperty('PhotoId'),
                                         }">
                                        <img :src="getPhoto(feature)" alt="">
                                    </div>
                                    <div class="cell auto inner__description">
                                        <div class="grid-x location">
                                            <div class="cell auto location__address">
                                                <div class="location-tag"
                                                     :class="`location-tag--${getTag(feature).toLowerCase()}`">
                                                    <h2 class="sr-only">{{ feature.getProperty('Title') }}</h2>
                                                    <p>{{ getTag(feature) }}</p>
                                                </div>

                                                <div>
                                                    <a :href="`/locations/${feature.getProperty('Slug')}`" class="title gtm-location-title">
                                                        {{ feature.getProperty('Title') }}
                                                    </a>
                                                    <p class="address">
                                                        <span class="address1" v-if="feature.getProperty('Address1')">
                                                            {{ feature.getProperty('Address1') }}
                                                        </span>
                                                        <span class="address2" v-if="feature.getProperty('Address2')">
                                                            {{ feature.getProperty('Address2') }}
                                                        </span>
                                                        &mdash;
                                                        <span class="city" v-if="feature.getProperty('City')">
                                                            {{ feature.getProperty('City') }},
                                                        </span>
                                                        <span class="state" v-if="feature.getProperty('State')">
                                                            {{ feature.getProperty('State') }}
                                                        </span>
                                                        <span class="zip" v-if="feature.getProperty('Zip')">
                                                            {{ feature.getProperty('Zip') }}
                                                        </span>
                                                        <span class="phone show-for-medium" v-if="feature.getProperty('Phone')">
                                                            <br>
                                                            <a :href="`tel:${feature.getProperty('Phone')}`">
                                                                <span class="sr-only">Phone number for {{ feature.getProperty('Title') }}: </span>{{ feature.getProperty('Phone') }}
                                                            </a>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="cell shrink text-right location__distance" v-if="feature.distance">
                                                {{ feature.distance }}mi
                                            </div>
                                        </div>
                                        <div class="grid-x grid-margin-y align-middle ctas">
                                            <div class="cell small-12 medium-6 ctas__cta ctas__cta--doctors">
                                                <a class="gtm-location-see-doctors" :data-label="`${feature.getProperty('Title')}`" :aria-label="`See Doctors in ${feature.getProperty('Title')}`"
                                                   :href="getDoctorsUrl(feature)"
                                                   v-if="feature.getProperty('HasProviders')">See Doctors</a>
                                            </div>
                                            <div class="cell small-12 hide-for-medium ctas__cta ctas__cta--phone" v-if="feature.getProperty('Phone')">
                                                <a class="a-btn a-btn-inverse a-btn--uppercase"
                                                   :aria-label="`Phone number for ${feature.getProperty('Title')}: ${feature.getProperty('Phone')}`"
                                                   :href="`tel:${feature.getProperty('Phone')}`">
                                                    Call {{ feature.getProperty('Phone') }}
                                                </a>
                                            </div>

                                            <div class="cell small-12 medium-6 ctas__cta ctas__cta--directions">
                                                <a class="a-external-link  gtm-location-get-directions"
                                                   :href="getDirectionsUrl(feature)" :data-label="`${feature.getProperty('Title')}`"
                                                   target="_blank"
                                                   :aria-label="`Get directions to ${feature.getProperty('Title')} (opens in new window)`">

                                                    Get Directions
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="a-app grid-x grid-margin-y align-center-middle" v-if="feature.getProperty('Id') === 74">
                                    <div class="cell small-12 a-app__map">
                                        <div class="grid-x grid-margin-x align-center-middle">
                                            <div class="cell shrink a-app__logo">
                                                <a href="#">
                                                    <img src="/Build/Images/Global/app/carle-app.png" alt="Carle Foundation App Logo">
                                                </a>
                                            </div>
                                            <div class="cell auto a-app__content">
                                                <p>Easily find your way around Carle Foundation Hospital with Carle Map.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cell small-12 a-app__actions">
                                        <div class="grid-x grid-margin-x align-center-middle">
                                            <div class="cell shrink a-app__action a-app__action--apple">
                                                <a href="#">
                                                    <img src="/Build/Images/Global/app/carle-app-apple.png" alt="Download on the Apple Store">
                                                </a>
                                            </div>
                                            <div class="cell shrink a-app__action a-app__action--google">
                                                <a href="#">
                                                    <img src="/Build/Images/Global/app/carle-app-google.png" alt="Get it on Google Play">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </section>
                    <section class="cell large-6 locations-container map-container"
                             :class="{ 'show-for-large': listView }"
                             aria-label="Map of location results">
                        <GoogleMap ref="map"
                                   :center="center"
                                   @ready="init">
                            <template v-if="loaded">
                                <Popup ref="popup">
                                    <template v-slot:desktop="popup">
                                        <div class="popup__head">
                                            <a :href="`/locations/${popup.feature.getProperty('Slug')}`" class="title">
                                                {{ popup.feature.getProperty('Title') }}
                                            </a>
                                        </div>
                                        <div class="popup__body">
                                            <p>
                                                <span class="address1" v-if="popup.feature.getProperty('Address1')">
                                                    {{ popup.feature.getProperty('Address1') }}
                                                </span>
                                                <span class="address2" v-if="popup.feature.getProperty('Address2')">
                                                    {{ popup.feature.getProperty('Address2') }}
                                                </span>
                                                &mdash;
                                                <span class="city" v-if="popup.feature.getProperty('City')">
                                                    {{ popup.feature.getProperty('City') }}
                                                </span>
                                                <span class="state" v-if="popup.feature.getProperty('State')">
                                                    {{ popup.feature.getProperty('State') }}
                                                </span>
                                                <span class="zip" v-if="popup.feature.getProperty('Zip')">
                                                    {{ popup.feature.getProperty('Zip') }}
                                                </span>
                                                <span class="phone" v-if="popup.feature.getProperty('Phone')">
                                                    <br>
                                                    <a :href="`tel:${popup.feature.getProperty('Phone')}`">
                                                        <span class="sr-only">Phone number for {{ popup.feature.getProperty('Title') }}: </span>{{ popup.feature.getProperty('Phone') }}
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                        <div class="popup__footer">
                                            <a v-if="popup.feature.getProperty('HasProviders')"
                                               :href="getDoctorsUrl(popup.feature)">
                                                See Doctors
                                            </a>
                                            <a :href="getDirectionsUrl(popup.feature)"
                                               target="_blank">
                                                Get Directions
                                            </a>
                                        </div>
                                    </template>
                                    <template v-slot:mobile="popup">
                                        <template v-if="popup.page === 1">
                                            <div class="popup__head">
                                                <a :href="`/locations/${popup.feature.getProperty('Slug')}`" class="title">
                                                    {{ popup.feature.getProperty('Title') }}
                                                </a>
                                            </div>
                                            <div class="popup__body">
                                                <p>
                                                    <span class="address1" v-if="popup.feature.getProperty('Address1')">
                                                        {{ popup.feature.getProperty('Address1') }}
                                                    </span>
                                                    <span class="address2" v-if="popup.feature.getProperty('Address2')">
                                                        {{ popup.feature.getProperty('Address2') }}
                                                    </span>
                                                    &mdash;
                                                    <span class="city" v-if="popup.feature.getProperty('City')">
                                                        {{ popup.feature.getProperty('City') }}
                                                    </span>
                                                    <span class="state" v-if="popup.feature.getProperty('State')">
                                                        {{ popup.feature.getProperty('State') }}
                                                    </span>
                                                    <span class="zip" v-if="popup.feature.getProperty('Zip')">
                                                        {{ popup.feature.getProperty('Zip') }}
                                                    </span>
                                                </p>
                                                <p><a :href="getDirectionsUrl(popup.feature)" target="_blank">Get Directions</a></p>
                                                <p>
                                                    <a :href="`tel:${popup.feature.getProperty('Phone')}`"
                                                       :aria-label="`Phone number for ${popup.feature.getProperty('Title')}: ${popup.feature.getProperty('Phone')}`"
                                                       class="a-btn-inverse"
                                                       v-if="popup.feature.getProperty('Phone')">
                                                        CALL {{ popup.feature.getProperty('Phone') }}
                                                    </a>
                                                </p>
                                            </div>
                                        </template>
                                        <template v-if="popup.page === 2">
                                            <div class="popup__body">
                                                <a href="#">
                                                    <img v-if="popup.feature.getProperty('PhotoId')"
                                                         :src="getPhoto(popup.feature)"
                                                         :alt="`Thumbnail of ${popup.feature.getProperty('Title')}`">
                                                    <a v-if="popup.feature.getProperty('HasProviders')"
                                                       :href="getDoctorsUrl(popup.feature)">
                                                        See Doctors at this location
                                                    </a>
                                                </a>
                                            </div>
                                        </template>
                                        <template v-if="popup.page === 3">
                                            <div class="popup__body">
                                                <div class="a-app grid-x align-middle grid-padding-y grid-margin-y" v-if="popup.feature.getProperty('Id') === 74">
                                                    <div class="cell small-12 medium-auto">
                                                        <div class="grid-x align-middle grid-padding-x">
                                                            <div class="cell shrink a-app__logo">
                                                                <a href="#">
                                                                    <img src="/Build/Images/Global/app/carle-app.png" alt="Carle Foundation App Logo">
                                                                </a>
                                                            </div>
                                                            <div class="cell auto a-app__content">
                                                                <p>Easily find your way around Carle Foundation Hospital with Carle Map.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cell small-12 medium-shrink">
                                                        <div class="grid-x align-center-middle grid-padding-x text-center">
                                                            <div class="cell auto a-app__action a-app__action--apple">
                                                                <a href="#">
                                                                    <img src="/Build/Images/Global/app/carle-app-apple.png" alt="Download on the Apple Store">
                                                                </a>
                                                            </div>
                                                            <div class="cell auto a-app__action a-app__action--google">
                                                                <a href="#">
                                                                    <img src="/Build/Images/Global/app/carle-app-google.png" alt="Get it on Google Play">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </Popup>
                            </template>
                        </GoogleMap>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import 'array-flat-polyfill';
    import 'url-search-params-polyfill';
    import GoogleMap from './GoogleMap.vue';
    import Popup from './Popup.vue';
    import { getUserPosition } from '@/Scripts/util/helpers';

    const params = new URLSearchParams(window.location.search);

    export default {
        name: 'LocationsMap',
        components: {
            GoogleMap,
            Popup
        },
        data() {
            return {
                loaded: false,
                features: [],
                listView: true,
                center: null,
                userCenter: null,
                enableControls: false,
                userTriggered: false,
                query: {
                    address: params.get('address') || '',
                    category: params.get('category') || '',
                    service: params.get('service') || '',
                },
            };
        },
        computed: {
            filteredFeatures() {
                return this.features
                    .filter(f => {
                        const serviceMatch = !this.query.service || (
                            f.getProperty('Services')
                                .map(s => s.toLowerCase())
                                .indexOf(this.query.service.toLowerCase()) !== -1
                        );
                        const categoryMatch = !this.query.category || (
                            f.getProperty('Categories')
                                .map(c => c.toLowerCase())
                                .indexOf(this.query.category.toLowerCase()) !== -1
                        );
                        const visible = serviceMatch && categoryMatch;
                        f.setProperty('active', visible);
                        return visible;
                    })
                    .map(f => {
                        f.distance = this.center ? this.getDistance(f.getGeometry().get(), this.center, 1) : false;
                        return f;
                    })
                    .sort((a, b) => {
                        if (!this.center) { return 0; }
                        if (a.distance < b.distance) { return -1; }
                        if (a.distance > b.distance) { return 1; }
                        return 0;
                    });
            },
            services() {
                const s = [...new Set(this.features
                    .map(f => f.getProperty('Services'))
                    .flat())].sort();
                return s;
            },
            categories() {
                return [...new Set(this.features
                    .map(f => f.getProperty('Categories'))
                    .flat())].sort();
            },
            queryIsValid() {
                let queryPass = true;
                if (!this.userTriggered) {
                    const hasCat = params.get('category') ? this.categories.map(c => c.toLowerCase()).includes(params.get('category').toLowerCase()) : true;
                    const hasSrv = params.get('service') ? this.services.map(s => s.toLowerCase()).includes(params.get('service').toLowerCase()) : true;
                    queryPass = hasCat && hasSrv;
                }
                return queryPass;
            },
        },
        methods: {
            init(map) {
                map.data.loadGeoJson(`${window.mvcVars.config.FapUrl}/Location/geojson`, null, (features) => {
                    this.features = features;
                    this.$nextTick(() => {
                        this.updateAddress();
                        this.loaded = true;
                    });
                });
                map.data.addListener('click', e => this.$refs.popup.open(e.feature));
                map.data.setStyle(f => {
                    const active = f.getProperty('active');
                    return { visible: active === undefined ? true : !!active };
                });
            },
            getTag(f) {
                return f.getProperty('Designations').indexOf('Inpatient') !== -1 ? 'Inpatient' : 'Outpatient';
            },
            getPhoto(f) {
                return `${window.mvcVars.config.FapUrl}/Media/Location/${f.getProperty('Id')}?notfound=Location.svg`;
            },
            getDoctorsUrl(f) {
                return `/Find-a-Doctor/results?location=${encodeURI(f.getProperty('Title'))}`;
            },
            getDirectionsUrl(f) {
                const latlng = f.getGeometry().get();
                const destination = `&destination=${latlng.lat()},${latlng.lng()}`;
                const origin = this.center && this.query.address ? `&origin=${this.center.lat()},${this.center.lng()}` : '';

                return `https://google.com/maps/dir/?api=1${origin}${destination}`;
            },
            getDistance(pt1, pt2, precision = 6) {
                const miles = this.$refs['map'].google.maps.geometry.spherical.computeDistanceBetween(pt1, pt2) / 1609.344;
                return miles.toFixed(precision) / 1;
            },
            getErrorField(field) {
                return !this.filteredFeatures.length ? this.query[field] : params.get(field);
            },
            async updateAddress(e) {
                if (e) {
                    this.userTriggered = true;
                } else if (!this.userCenter) {
                    try {
                        const center = await getUserPosition();
                        this.userCenter = new google.maps.LatLng(center);
                    } catch (e) {}
                }

                this.query.address = this.$refs.address.value;
                this.query.category = this.$refs.category.value;
                this.query.service = this.$refs.service.value;
                this.enableControls = false;

                if (this.query.address.trim().toLowerCase() === 'my current location') {
                    if (this.userCenter) {
                        this.center = this.userCenter;
                        if (this.$refs.features && this.userTriggered) {
                            this.$refs.features.focus();
                        }
                    } else {
                        try {
                            const center = await getUserPosition();
                            this.userCenter = new google.maps.LatLng(center);
                            this.center = this.userCenter;
                            if (this.$refs.features && this.userTriggered) {
                                this.$refs.features.focus();
                            }
                        } catch (e) {
                            alert('It appears you have Goelocation permissions turned off. Please update your browser settings and refresh the page, or enter your address.');
                            this.$refs.address.value = '';
                            this.$refs.address.focus();
                        }
                    }
                }
                else if (this.query.address) {
                    try {
                        this.center = await this.$refs['map'].geocode(this.query.address);
                        if (this.$refs.features && this.userTriggered) {
                            this.$refs.features.focus();
                        }
                    }
                    catch (e) {
                        alert(e);
                        this.$refs.address.focus();
                    }
                } else {
                    this.center = this.userCenter;
                    if (this.$refs.features && this.userTriggered) {
                        this.$refs.features.focus();
                    }
                }
            },
        },
    };
</script>