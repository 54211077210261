var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "content", staticClass: "popup" }, [
    _vm.currentFeature
      ? _c(
          "div",
          { staticClass: "show-for-large popup__inner popup__inner--desktop" },
          [_vm._t("desktop", null, { feature: _vm.currentFeature })],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.currentFeature
      ? _c(
          "div",
          { staticClass: "hide-for-large popup__inner popup__inner--mobile" },
          [
            _c("div", { staticClass: "popup__navigation" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("span", { staticClass: "cur-page" }, [
                _vm._v(_vm._s(_vm.page))
              ]),
              _vm._v(" / "),
              _c("span", { staticClass: "max-page" }, [
                _vm._v(_vm._s(_vm.getPages().length))
              ]),
              _vm._v(" "),
              _vm._m(1)
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "popup__page popup__page--p${page}" },
              [
                _vm._t("mobile", null, {
                  feature: _vm.currentFeature,
                  page: _vm.page
                })
              ],
              2
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "pager pager--down", attrs: { href: "#" } }, [
      _c("i", { staticClass: "fa fa-chevron-left" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "pager pager--up", attrs: { href: "#" } }, [
      _c("i", { staticClass: "fa fa-chevron-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }