<template>
    <div class="tabbed-menu-wrap grid-container">
        <div class="grid-x">
            <div class="cell large-12 tab-button-wrap">
                <button class="tab-button"
                        :class="{ 'active': name === selected }"
                        v-for="text, name in tabs"
                        @click="handleClick(name)">
                    {{ text }}
                </button>
            </div>
            <div class="cell large-10 large-offset-1 tabbed-menu">
                <slot :selected="selected" v-if="selected"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Tabs',
        props: {
            tabs: Object,
            active: String,
            handler: Function,
        },
        data() {
            return {
                selected: this.active,
            };
        },
        methods: {
            handleClick(name) {
                this.selected = this.handler ? this.handler(name) : name;
                this.$emit('click', name);
            }
        },
    }
</script>