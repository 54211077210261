<template>
    <div class="form-container">
        <form class="grid-x grid-margin-y" @submit="send" ref="form" novalidate>
            <div class="small-12 cell">
                <fieldset aria-required>
                    <legend>Are you recognizing an individual or group?</legend>
                    <div class="grid-x grid-margin-x">
                        <div class="shrink cell radio-checkbox radio-checkbox--circle">
                            <input type="radio"
                                   name="RecognitionType"
                                   :value="4"
                                   id="RecognitionType-individual-radio"
                                   required
                                   v-model="data.RecognitionType">
                            <label for="RecognitionType-individual-radio">Individual</label>
                        </div>
                        <div class="shrink cell radio-checkbox radio-checkbox--circle">
                            <input type="radio"
                                   name="RecognitionType"
                                   :value="5"
                                   id="RecognitionType-group-radio"
                                   required
                                   v-model="data.RecognitionType">
                            <label for="RecognitionType-group-radio">Group</label>
                        </div>
                    </div>
                </fieldset>
            </div>
            <template v-if="data.RecognitionType !== null">
                <div class="small-12 cell">
                    <fieldset v-if="data.RecognitionType === 4">
                        <legend>Who are you recognizing?</legend>
                        <div class="grid-x grid-margin-x">
                            <div class="small-12 medium-6 cell">
                                <label for="rec-FirstName-input">First Name</label>
                                <small v-if="errors.Receiver.FirstName" class="a-input-error">
                                    Individual First Name is required
                                </small>
                                <input type="text"
                                       name="Receiver[FirstName]"
                                       class="m-search__field"
                                       v-model="data.Receiver.FirstName"
                                       id="rec-FirstName-input"
                                       aria-required
                                       @blur="errors.Receiver.FirstName = false">
                            </div>
                            <div class="small-12 medium-6 cell">
                                <label for="rec-LastName-input">Last Name</label>
                                <small v-if="errors.Receiver.LastName" class="a-input-error">
                                    Individual Last Name is required
                                </small>
                                <input type="text"
                                       name="Receiver[LastName]"
                                       class="m-search__field"
                                       v-model="data.Receiver.LastName"
                                       id="rec-LastName-input"
                                       aria-required
                                       @blur="errors.Receiver.LastName = false">
                            </div>
                        </div>
                    </fieldset>
                    <template v-if="data.RecognitionType === 5">
                        <label for="rec-dept-input">What is the name of the Group - department, unit, or floor - you want to recognize?</label>
                        <small v-if="errors.Receiver.Department" class="a-input-error">
                            Group Department is required
                        </small>
                        <input type="text"
                               name="Receiver[Department]"
                               class="m-search__field"
                               v-model="data.Receiver.Department"
                               id="rec-dept-input"
                               aria-required
                               @blur="errors.Receiver.Department = false">
                    </template>
                </div>
                <div class="small-12 cell">
                    <fieldset aria-required>
                        <legend>Are you recognizing {{ data.RecognitionType === 4 ? 'an individual' : 'a group' }} from...</legend>
                        <small v-if="errors.Receiver.EntityType" class="a-input-error">
                            Please select the {{ data.RecognitionType === 4 ? 'Individual' : 'Group' }}'s location
                        </small>
                        <div class="grid-x grid-margin-x">
                            <div class="small-12 cell radio-checkbox radio-checkbox--circle">
                                <input id="EntityType-carle-radio"
                                       name="Receiver[EntityType]"
                                       type="radio"
                                       :value="1"
                                       v-model="data.Receiver.EntityType"
                                       required
                                       @blur="errors.Receiver.EntityType = false">
                                <label for="EntityType-carle-radio">Carle</label>
                            </div>
                            <div class="small-12 cell radio-checkbox radio-checkbox--circle">
                                <input id="EntityType-healthAlliance-radio"
                                       name="Receiver[EntityType]"
                                       type="radio"
                                       :value="2"
                                       v-model="data.Receiver.EntityType"
                                       required
                                       @blur="errors.Receiver.EntityType = false">
                                <label for="EntityType-healthAlliance-radio">Health Alliance</label>
                            </div>
                            <div class="small-12 cell radio-checkbox radio-checkbox--circle">
                                <input id="EntityType-stratumMed-radio"
                                       name="Receiver[EntityType]"
                                       type="radio"
                                       :value="3"
                                       v-model="data.Receiver.EntityType"
                                       required
                                       @blur="errors.Receiver.EntityType = false">
                                <label for="EntityType-stratumMed-radio">StratumMed</label>
                            </div>
                            <div class="small-12 cell radio-checkbox radio-checkbox--circle">
                                <input id="EntityType-carleHoopeston-radio"
                                       name="Receiver[EntityType]"
                                       type="radio"
                                       :value="4"
                                       v-model="data.Receiver.EntityType"
                                       required
                                       @blur="errors.Receiver.EntityType = false">
                                <label for="EntityType-carleHoopeston-radio">Carle Hoopeston</label>
                            </div>
                            <div class="small-12 cell radio-checkbox radio-checkbox--circle">
                                <input id="EntityType-dunno-radio"
                                       name="Receiver[EntityType]"
                                       type="radio"
                                       :value="5"
                                       v-model="data.Receiver.EntityType"
                                       required
                                       @blur="errors.Receiver.EntityType = false">
                                <label for="EntityType-dunno-radio">Do Not Know</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="small-12 cell" v-if="data.RecognitionType === 4">
                    <fieldset aria-required>
                        <legend>What does the Individual do?</legend>
                        <small v-if="errors.Receiver.JobType" class="a-input-error">
                            Please select the Individual's Job Type'
                        </small>
                        <div class="grid-x grid-margin-x">
                            <div class="shrink cell radio-checkbox radio-checkbox--circle">
                                <input type="radio"
                                       name="Receiver[JobType]"
                                       :value="1"
                                       id="Receiver-doctor-radio"
                                       v-model="data.Receiver.JobType"
                                       required
                                       @blur="errors.Receiver.JobType = false">
                                <label for="Receiver-doctor-radio">Doctor</label>
                            </div>
                            <div class="shrink cell radio-checkbox radio-checkbox--circle">
                                <input type="radio"
                                       name="Receiver[JobType]"
                                       :value="2"
                                       id="Receiver-nurse-radio"
                                       v-model="data.Receiver.JobType"
                                       required
                                       @blur="errors.Receiver.JobType = false">
                                <label for="Receiver-nurse-radio">Nurse</label>
                            </div>
                            <div class="shrink cell radio-checkbox radio-checkbox--circle">
                                <input type="radio"
                                       name="Receiver[JobType]"
                                       :value="3"
                                       id="Receiver-other-radio"
                                       v-model="data.Receiver.JobType"
                                       required
                                       @blur="errors.Receiver.JobType = false">
                                <label for="Receiver-other-radio">Other</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="small-12 cell">
                    <fieldset>
                        <legend>What is your name?</legend>
                        <div class="grid-x grid-margin-x">
                            <div class="small-12 medium-6 cell">
                                <label for="req-FirstName-input">First Name</label>
                                <small v-if="errors.Requestor.FirstName" class="a-input-error">
                                    Please tell us your First Name
                                </small>
                                <input type="text"
                                       name="Requestor[FirstName]"
                                       class="m-search__field"
                                       v-model="data.Requestor.FirstName"
                                       id="req-FirstName-input"
                                       aria-required
                                       @blur="errors.Requestor.FirstName = false">
                            </div>
                            <div class="small-12 medium-6 cell">
                                <label for="req-LastName-input">Last Name</label>
                                <small v-if="errors.Requestor.LastName" class="a-input-error">
                                    Please tell us your Last Name
                                </small>
                                <input type="text"
                                       name="Requestor[LastName]"
                                       class="m-search__field"
                                       v-model="data.Requestor.LastName"
                                       id="req-LastName-input"
                                       aria-required
                                       @blur="errors.Requestor.LastName = false">
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="small-12 cell">
                    <label for="req-Email-input">What is your email? (optional)</label>
                    <small v-if="errors.Requestor.Email" class="a-input-error">
                        Please enter a valid email
                    </small>
                    <input type="text"
                           name="Requestor[Email]"
                           class="m-search__field"
                           v-model="data.Requestor.Email"
                           id="req-Email-input"
                           @blur="errors.Requestor.Email = false">
                </div>
                <div class="small-12 cell">
                    <label for="req-Phone-input">What is your phone number? (optional)</label>
                    <input type="text"
                           name="Requestor[Phone]"
                           class="m-search__field"
                           v-model="data.Requestor.Phone"
                           id="req-Phone-input">
                </div>
                <div class="small-12 cell">
                    <label for="msg-Story-text">Tell us your Story. Describe your interaction with the employee and what made it special.</label>
                    <small>Characters Remaining: {{ charLimit - data.Message.Story.length }}</small>
                    <small v-if="errors.Message.Story" class="a-input-error">
                        <br>Please tell us your Story
                    </small>
                    <textarea name="Message[Story]"
                              v-model="data.Message.Story"
                              id="msg-Story-text"
                              aria-required
                              @keypress="charCheck($event, data.Message.Story)"
                              @blur="errors.Message.Story = false"></textarea>
                </div>
                <div class="small-12 cell">
                    <label for="msg-Applause-text">Optional - Please provide any additional detail about your visit to help us identify the individual (Department/Unit, date of visit, room number, etc).</label>
                    <small>Characters Remaining: {{ charLimit - data.Message.Applause.length }}</small>
                    <textarea name="Message[Applause]"
                              v-model="data.Message.Applause"
                              id="msg-Applause-text"
                              @keypress="charCheck($event, data.Message.Applause)"></textarea>
                </div>
                <div class="small-12 cell checkbox">
                    <input type="checkbox" name="Requestor[MayContact]" id="req-MayContact-check" v-model="data.Requestor.MayContact">
                    <label for="req-MayContact-check">(OPTIONAL) I give Carle permission to contact me to discuss the possibility of sharing my Story with the public.</label>
                </div>
                <div class="small-12 cell">
                    <button class="a-btn-main"
                            :class="{ 'a-btn--loading': sending }">Submit</button>
                </div>
                <div class="small-12 cell" v-if="hasErrors">
                    <small class="a-input-error">Your form has some errors. Please review your information and try again.</small>
                </div>
            </template>
            <input type="hidden" name="Id" value="1">
            <slot></slot>
        </form>
        <div class="grid-x success-msg" ref="success">
            Thank you for your input!
        </div>
    </div>
</template>

<style lang="scss" scoped>
    textarea {
        border: 1px solid #555559;
        height: 150px;
    }
    .success-msg{
        display: none;
    }
    .a-input-error {
        color: #C32032;
    }
    [type=checkbox], [type=radio] {
        & + label { text-transform: none; }
    }
</style>

<script>
    import $ from 'jQuery';
    export default {
        name: 'Goldstar',
        data() {
            return {
                sending: false,
                data: {
                    RecognitionType: null,
                    Receiver: {
                        FirstName: '',
                        LastName: '',
                        EntityType: null,
                        JobType: null,
                        Department: '',
                    },
                    Requestor: {
                        FirstName: '',
                        LastName: '',
                        Phone: '',
                        Email: '',
                        MayContact: false,
                    },
                    Message: {
                        Applause: '',
                        Story: '',
                    },
                },
                charLimit: 300,
                hasErrors: false,
                errors: {
                    Receiver: {
                        FirstName: false,
                        LastName: false,
                        Department: false,
                        EntityType: false,
                        JobType: false,
                    },
                    Requestor: {
                        FirstName: false,
                        LastName: false,
                        Email: false,
                    },
                    Message: {
                        Story: false,
                    },
                },
            };
        },
        computed: {
            entity() {
                return ['None Selected', 'Carle', 'Health Alliance', 'StratumMed', 'Carle Hoopeston', 'Do Not Know'][this.data.Receiver.EntityType];
            },
        },
        methods: {
            async send(e) {
                e.preventDefault();
                this.sending = true;
                this.hasErrors = false;
                this.checkErrors();

                if (!this.hasErrors) {
                    const csrf = $(this.$refs.form).find('input[name="__RequestVerificationToken"]').val();
                    const payload = Object.assign({}, this.data);
                    payload['Receiver']['Entity'] = this.entity;
                    if (this.data.RecognitionType === 5) {
                        payload['Receiver']['JobType'] = 1;
                    }

                    const token = await $.post('/Patients-Visitors/Patient-Resources/Thanks', {
                        __RequestVerificationToken: csrf,
                    });
                    try {
                        await $.ajax({
                            url: 'https://www.carle.com/GoldStarApi/api/GoldStar',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                            },
                            method: 'POST',
                            data: payload
                        });
                        $(this.$refs.form).slideUp(() => {
                            $(this.$refs.success).fadeIn();
                        });
                    } catch (e) {
                        // Fail silently. Payload is likely malformed
                    }
                }
                this.sending = false;
            },
            charCheck(e, v) {
                if (v.length >= this.charLimit) {
                    e.preventDefault();
                }
            },
            checkErrors() {
                const check = (check) => {
                    if (check) {
                        this.hasErrors = true;
                    }
                    return check;
                };
                
                this.errors = {
                    Receiver: {
                        FirstName: check(!this.data.Receiver.FirstName && this.data.RecognitionType === 4),
                        LastName: check(!this.data.Receiver.LastName && this.data.RecognitionType === 4),
                        Department: check(!this.data.Receiver.Department && this.data.RecognitionType === 5),
                        EntityType: check(!this.data.Receiver.EntityType),
                        JobType: check(!this.data.Receiver.JobType && this.data.RecognitionType === 4),
                    },
                    Requestor: {
                        FirstName: check(!this.data.Requestor.FirstName),
                        LastName: check(!this.data.Requestor.LastName),
                        Email: check(!!this.data.Requestor.Email &&
                            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.data.Requestor.Email)
                        ),
                    },
                    Message: {
                        Story: check(!this.data.Message.Story),
                    },
                };
            },
        },
    }
</script>