var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-api" }, [
    _vm.displayLoad && _vm.loading
      ? _c(
          "div",
          { staticClass: "m-api__loading" },
          [_vm._t("loading", [_vm._v("Loading...")])],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.result
      ? _c(
          "div",
          { staticClass: "m-api__result" },
          [_vm._t("default", null, { result: _vm.result })],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.displayError && _vm.error
      ? _c(
          "div",
          { staticClass: "m-api__error" },
          [
            _vm._t("error", [_vm._v("An error has occurred")], {
              error: _vm.error
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }