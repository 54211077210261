<template>
    <li class="twitter">
        <a href="#" @click.prevent="openUrl">
            <i class="fa fa-twitter-square" aria-hidden="true"></i>
            <span class="show-for-sr">Link to share via Twitter</span>
        </a>
    </li>
</template>

<script type="text/javascript">
    export default {
        name: 'Twitter',
        props: {
            url: {
                type: String,
                default: window.location.href
            },
        },
        computed: {
            shareUrl() {
                return `https://twitter.com/intent/tweet?url=${this.url}&text=${this.getBody()}`;
            }
        },
        methods: {
            openUrl() {
                this.$parent.$emit('open', this.shareUrl, 'twitter');
            },
            getBody() {
                if (this.$slots.default.length) {
                    return encodeURIComponent(this.$slots.default[0].text.trim());
                }
                return '';
            },
        },
    };
</script>