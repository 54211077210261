<template>
    <div class="m-popover" @click.stop="">
        <button class="a-open-panel"
                aria-haspopup="dialog"
                :aria-controls="controlsElement"
                ref="control"
                @click.prevent="toggle">
            <slot name="button"></slot>
        </button>
        <FocusTrap v-model="trapped">
            <div class="a-link-panel"
                 role="dialog"
                 aria-modal="true"
                 tabindex="-1"
                 :aria-label="panelLabel"
                 :id="controlsElement"
                 ref="panel"
                 @keydown.esc="close">
                <div class="a-link-panel__inner">
                    <div class="grid-x">
                        <div class="small-12 cell">
                            <div class="grid-x align-middle">
                                <div class="cell auto">
                                    <slot name="heading">
                                        <em>{{ panelLabel }}</em>
                                    </slot>
                                </div>
                                <div class="cell shrink">
                                    <button :aria-label="closeLabel" class="a-link-panel__close" @click.stop.prevent="close">
                                        <i class="fa fa-close"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="small-12 cell">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    </div>
</template>

<script type="text/javascript">
    import $ from 'jQuery';
    import { FocusTrap } from 'focus-trap-vue'
    export default {
        name: 'Popover',
        components: {
            FocusTrap
        },
        props: {
            controlsElement: {
                type: String,
                required: true,
            },
            panelLabel: {
                type: String,
                required: true,
            },
            closeLabel: {
                type: String,
                default: 'Close Popup'
            },
        },
        data() {
            return {
                trapped: false,
            };
        },
        methods: {
            toggle() {
                const panel = $(this.$refs.panel);
                if (panel.is(':visible')) {
                    this.close();
                } else {
                    this.open();
                }
            },
            open() {
                const panel = $(this.$refs.panel);
                if (!panel.is(':visible')) {
                    panel.slideDown(250, () => {
                        this.trapped = true;
                        this.$emit('opened');
                    });
                    this.$emit('open');
                }
            },
            close() {
                const panel = $(this.$refs.panel);
                if (panel.is(':visible')) {
                    panel.slideUp(250, () => {
                        this.trapped = false;
                        this.$emit('closed');
                    });
                    this.$emit('close');
                }
            },
        },
        mounted() {
            $('body').on('click', this.close);
        }
    }
</script>