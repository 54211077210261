var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "o-single-location" },
    [
      _vm._t("before", null, { location: _vm.location }),
      _vm._v(" "),
      _vm._t(
        "default",
        [
          _c("div", { staticClass: "grid-container grid-container-small" }, [
            _c("div", { staticClass: "grid-x align-center" }, [
              _c("div", { staticClass: "small-12 cell m-location" }, [
                _c("div", { staticClass: "grid-x" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cell small-12 small-order-2 medium-auto large-order-1 m-location__result"
                    },
                    [
                      _c("div", { staticClass: "result-body" }, [
                        _c("h1", { staticClass: "location-title" }, [
                          _vm._v(_vm._s(_vm.location.Title))
                        ]),
                        _vm._v(" "),
                        _vm.location.Notes
                          ? _c("p", { staticClass: "location-notes" }, [
                              _vm._v(_vm._s(_vm.location.Notes))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "location-tag",
                            class: "location-tag--" + _vm.tag.toLowerCase()
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.tag) +
                                "\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "location-info" }, [
                          _c("span", { staticClass: "address-1" }, [
                            _vm._v(_vm._s(_vm.location.Address1))
                          ]),
                          _vm._v(" "),
                          _vm.location.Address2
                            ? _c("span", { staticClass: "address-2" }, [
                                _vm._v(_vm._s(_vm.location.Address2))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "city" }, [
                            _vm._v(" - " + _vm._s(_vm.location.City) + ", ")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "state" }, [
                            _vm._v(_vm._s(_vm.location.State))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "zip" }, [
                            _vm._v(_vm._s(_vm.location.Zip))
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.location.Phone
                          ? _c("p", { staticClass: "location-phone" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.location.Phone) +
                                  "\n                                "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "result-cta grid-x grid-margin-x grid-margin-y align-middle"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "small-12 medium-shrink cell result-cta__directions"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "a-btn-main a-btn--uppercase a-external-link",
                                  attrs: {
                                    href: _vm.directions,
                                    target: "_blank",
                                    "aria-label":
                                      "Get directions to " +
                                      _vm.location.Title +
                                      " (opens in new window)"
                                  }
                                },
                                [_vm._v("Get Directions")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.location.HasProviders
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "small-6 medium-shrink cell result-cta__doctors"
                                },
                                [
                                  _c("a", { attrs: { href: _vm.search } }, [
                                    _vm._v("See Doctors")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.map
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "small-6 medium-shrink cell result-cta__campus"
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: _vm.map, target: "_blank" }
                                    },
                                    [_vm._v("Campus Map")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.location.PhotoId
                    ? _c("div", {
                        staticClass:
                          "cell small-12 small-order-1 medium-shrink large-order-2 m-location__image",
                        style: { "background-image": "url(" + _vm.image + ")" }
                      })
                    : _vm._e()
                ])
              ])
            ])
          ])
        ],
        { location: _vm.location, directions: _vm.directions }
      ),
      _vm._v(" "),
      _vm._t("alert", null, { location: _vm.location }),
      _vm._v(" "),
      _vm._t("after", null, { location: _vm.location }),
      _vm._v(" "),
      _c("script", { attrs: { type: "application/ld+json" } }, [
        _vm._v(
          '\n        {\n        "@context": "http://schema.org",\n        "@type": "Organization",\n        "name": "' +
            _vm._s(_vm.location.Title) +
            '",\n        "location": {\n        "@type": "Place",\n        "address": {\n        "@type": "PostalAddress",\n        "streetAddress": "' +
            _vm._s(_vm.location.Address1) +
            '"\n        "addressLocality": "' +
            _vm._s(_vm.location.City) +
            '",\n        "addressRegion": "' +
            _vm._s(_vm.location.State) +
            '",\n        "postalCode": "' +
            _vm._s(_vm.location.Zip) +
            '"\n        },\n        "url": "/locations/' +
            _vm._s(_vm.location.Slug) +
            '"\n        }\n        }\n    '
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }