<template>
    <div class="o-single-provider" itemscope="" itemtype="http://schema.org/Physician">
        <slot name="before" v-bind:provider="provider"></slot>
        <slot v-bind:provider="provider">
            <div class="grid-container m-provider">
                <div class="grid-x align-center">
                    <div class="cell small-12 large-10">
                        <div class="grid-x grid-padding-x provider-card">
                            <div class="cell auto">
                                <div class="grid-x grid-margin-x provider-card__profile">
                                    <div class="cell small-12 large-4 provider-card__image">
                                        <img itemprop="image" :src="image" :alt="'Photo of ' + fullName">
                                    </div>
                                    <div class="cell small-12 large-8 provider-card__name">
                                        <p class="fullname" itemprop="name">{{ fullName }}</p>
                                        <p v-if="provider.Specialties.length">
                                            <span itemtype="http://schema.org/MedicalSpecialty"
                                                  itemscope=""
                                                  itemprop="medicalSpecialty"
                                                  v-for="(specialty, i) in provider.Specialties">
                                                <template v-if="i !== 0">,</template>
                                                <span itemprop="name">{{ specialty }}</span>
                                            </span>
                                        </p>
                                        <p v-if="provider.VimeoId">
                                            <a :href="`https://player.vimeo.com/video/${provider.VimeoId}`" class="a-btn-main a-btn--uppercase a-btn--video" target="_blank">
                                                <i class="fa fa-play"></i> Watch Video
                                            </a>
                                        </p>
                                        <template v-if="!sfcri">
                                            <div class="m-star-rating" v-if="ratings.valid && ratings.profile.reviewcount > 0 && provider.HasStars">
                                                <a href="#comments" class="m-star-rating__anchor" itemtype="http://schema.org/AggregateRating" itemscope="" itemprop="aggregateRating">
                                                    <span class="m-star-rating__star" v-for="n in 5">
                                                        <i class="fa fa-star" v-if="n <= Math.floor(ratings.profile.averageRatingStr)"></i>
                                                        <i class="fa fa-star-half-o" v-else-if="n <= Math.ceil(ratings.profile.averageRatingStr)"></i>
                                                        <i class="fa fa-star-o" v-else></i>
                                                    </span>
                                                    <span class="m-star-rating__score">
                                                        <span itemprop="ratingValue">
                                                            {{ ratings.profile.averageRatingStr }}
                                                        </span> out of 5 (<span itemprop="ratingCount">{{ ratings.profile.reviewcount }}</span> Ratings, {{ ratings.profile.bodyCountStr }})
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="m-star-rating" v-else>
                                                <span class="m-star-rating__star" v-for="n in 5">
                                                    <i class="fa fa-star-o"></i>
                                                </span>
                                                <span class="m-star-rating__score">Not yet rated</span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="cell large-4" v-if="provider.Locations.length">
                                <div class="grid-x provider-card__location" itemtype="http://schema.org/Hospital" itemscope="" itemprop="hospitalAffiliation">
                                    <div class="cell small-12">
                                        <p>
                                            <a :href="'/locations/' + provider.Locations[0].Slug">
                                                <strong itemprop="name">{{ provider.Locations[0].Title }}</strong>
                                            </a> (Primary Location)
                                        </p>
                                    </div>
                                    <div class="cell small-12">
                                        <p itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
                                            <span class="address" itemprop="streetAddress">
                                                {{ provider.Locations[0].Address1 }}
                                                <br v-if="provider.Locations[0].Address2">{{ provider.Locations[0].Address2 }}
                                            </span><br>
                                            <span class="cityState">
                                                <span itemprop="addressLocality">{{ provider.Locations[0].City }},</span>
                                                <span itemprop="addressRegion">{{ provider.Locations[0].State }}</span>
                                                <span itemprop="postalCode">{{ provider.Locations[0].Zip }}</span>
                                            </span>
                                        </p>
                                        <p v-if="provider.Locations[0].Phone">
                                            <strong>Main Office: </strong>
                                            <a :href="'tel:' + provider.Locations[0].Phone">
                                                {{ provider.Locations[0].Phone }}
                                            </a>
                                        </p>
                                    </div>
                                    <div class="cell small-12" v-if="provider.Locations.length > 1">
                                        <p>
                                            <strong>Other Locations: </strong>
                                            <a v-for="i in provider.Locations.length - 1" :href="'/locations/' + provider.Locations[i].Slug">
                                                {{ provider.Locations[i].Title }}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid-x grid-padding-x provider-details">
                            <template v-if="sfcri">
                                <div class="cell small-12 provider-detail" v-if="provider.Fellowships.length">
                                    <p class="provider-detail__heading">Fellowships</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="fellowship in provider.Fellowships">
                                            <span v-if="fellowship.Focus">{{ fellowship.Focus }}, </span>
                                            <template v-if="fellowship.Institute">
                                                <span v-if="fellowship.Institute.Title">{{ fellowship.Institute.Title }}, </span>
                                                <span v-if="fellowship.Institute.City">{{ fellowship.Institute.City }}, </span>
                                                <span v-if="fellowship.Institute.State">{{ fellowship.Institute.State }}, </span>
                                                <span v-if="fellowship.Institute.Country">{{ fellowship.Institute.Country }}, </span>
                                            </template>
                                            <span>{{ fellowship.Years }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Residencies.length">
                                    <p class="provider-detail__heading">Residency</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="residency in provider.Residencies">
                                            <span v-if="residency.Focus">{{ residency.Focus }}, </span>
                                            <template v-if="residency.Institute">
                                                <span v-if="residency.Institute.Title">{{ residency.Institute.Title }}, </span>
                                                <span v-if="residency.Institute.City">{{ residency.Institute.City }}, </span>
                                                <span v-if="residency.Institute.State">{{ residency.Institute.State }}, </span>
                                                <span v-if="residency.Institute.Country">{{ residency.Institute.Country }}, </span>
                                            </template>
                                            <span>{{ residency.Years }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Education.length">
                                    <p class="provider-detail__heading">Education</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="education in provider.Education">
                                            <span v-if="education.Focus">{{ education.Focus }}, </span>
                                            <template v-if="education.Institute">
                                                <span v-if="education.Institute.Title">{{ education.Institute.Title }}, </span>
                                                <span v-if="education.Institute.City">{{ education.Institute.City }}, </span>
                                                <span v-if="education.Institute.State">{{ education.Institute.State }}, </span>
                                                <span v-if="education.Institute.Country">{{ education.Institute.Country }}, </span>
                                            </template>
                                            <span>{{ education.Years }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.ResearchInterests.length">
                                    <p class="provider-detail__heading">Research Interests</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="interest in provider.ResearchInterests">
                                            {{ interest }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.ExternalLinks.length">
                                    <p class="provider-detail__heading">External Resources</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="link in provider.ExternalLinks">
                                            <a class="a-external-link" :href="link.Url" target="_blank">{{ link.Title || link.Url }}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Publications.length">
                                    <p class="provider-detail__heading">Publications</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="publication in provider.Publications">
                                            {{ publication }}
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template v-else>
                                <div class="cell small-12 provider-detail" v-if="provider.MedicalInterests.length">
                                    <p class="provider-detail__heading">Medical Interests</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="interest in provider.MedicalInterests">
                                            {{ interest }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Fellowships.length">
                                    <p class="provider-detail__heading">Fellowships</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="fellowship in provider.Fellowships">
                                            <span v-if="fellowship.Focus">{{ fellowship.Focus }}, </span>
                                            <template v-if="fellowship.Institute">
                                                <span v-if="fellowship.Institute.Title">{{ fellowship.Institute.Title }}, </span>
                                                <span v-if="fellowship.Institute.City">{{ fellowship.Institute.City }}, </span>
                                                <span v-if="fellowship.Institute.State">{{ fellowship.Institute.State }}, </span>
                                                <span v-if="fellowship.Institute.Country">{{ fellowship.Institute.Country }}, </span>
                                            </template>
                                            <span>{{ fellowship.Years }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Residencies.length">
                                    <p class="provider-detail__heading">Residency</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="residency in provider.Residencies">
                                            <span v-if="residency.Focus">{{ residency.Focus }}, </span>
                                            <template v-if="residency.Institute">
                                                <span v-if="residency.Institute.Title">{{ residency.Institute.Title }}, </span>
                                                <span v-if="residency.Institute.City">{{ residency.Institute.City }}, </span>
                                                <span v-if="residency.Institute.State">{{ residency.Institute.State }}, </span>
                                                <span v-if="residency.Institute.Country">{{ residency.Institute.Country }}, </span>
                                            </template>
                                            <span>{{ residency.Years }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Internships.length">
                                    <p class="provider-detail__heading">Internships</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="internship in provider.Internships">
                                            <span v-if="internship.Focus">{{ internship.Focus }}, </span>
                                            <template v-if="internship.Institute">
                                                <span v-if="internship.Institute.Title">{{ internship.Institute.Title }}, </span>
                                                <span v-if="internship.Institute.City">{{ internship.Institute.City }}, </span>
                                                <span v-if="internship.Institute.State">{{ internship.Institute.State }}, </span>
                                                <span v-if="internship.Institute.Country">{{ internship.Institute.Country }}, </span>
                                            </template>
                                            <span>{{ internship.Years }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Education.length">
                                    <p class="provider-detail__heading">Education</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="education in provider.Education">
                                            <span v-if="education.Focus">{{ education.Focus }}, </span>
                                            <template v-if="education.Institute">
                                                <span v-if="education.Institute.Title">{{ education.Institute.Title }}, </span>
                                                <span v-if="education.Institute.City">{{ education.Institute.City }}, </span>
                                                <span v-if="education.Institute.State">{{ education.Institute.State }}, </span>
                                                <span v-if="education.Institute.Country">{{ education.Institute.Country }}, </span>
                                            </template>
                                            <span>{{ education.Years }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Certifications.length">
                                    <p class="provider-detail__heading">Certifications</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="certification in provider.Certifications">
                                            {{ certification }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="cell small-12 provider-detail" v-if="provider.Affiliations.length">
                                    <p class="provider-detail__heading">Professional Societies</p>
                                    <ul class="provider-detail__list">
                                        <li v-for="affiliation in provider.Affiliations">
                                            {{ affiliation }}
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="ratings.valid && provider.HasStars && !sfcri">
                <span id="comments"></span>
                <div class="grid-container m-reviews">
                    <div class="grid-x grid-padding-x align-center" v-if="ratings.reviews.length">
                        <div class="small-12 large-10 cell">
                            <h2>Comments</h2>
                            <div class="grid-x grid-margin-x m-reviews__review" v-for="review in ratings.reviews">
                                <div class="small-shrink medium-2 cell">
                                    <strong>{{ convertDate(review.formattedReviewDate) }}</strong>
                                </div>
                                <div class="small-auto medium-10 cell">
                                    <p v-html="review.bodyForDisplay">{{ review.bodyForDisplay }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </slot>
        <slot name="after" v-bind:provider="provider"></slot>
    </div>
</template>

<script type="text/javascript">
    import 'magnific-popup';

    const params = new URLSearchParams(window.location.search);
    export default {
        name: 'Provider',
        props: {
            provider: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                sfcri: params.get('sfcri') === '1',
                ratings: false,
            };
        },
        computed: {
            fullName() {
                return `${this.provider.FirstName} ${this.provider.LastName}, ${this.provider.Title}`;
            },
            image() {
                return `${window.mvcVars.config.FapUrl}/Media/Provider/${this.provider.Id}?notfound=Provider.jpg`;
            },
        },
        methods: {
            convertDate(dateStr) {
                const d = new Date(dateStr);

                const year = d.getFullYear();
                const month = (1 + d.getMonth()).toString().padStart(2, '0');
                const day = d.getDate().toString().padStart(2, '0');
                
                return `${month}/${day}/${year}`;
            },
        },
        async mounted() {
            const ratings = await $.ajax({
                url: `https://transparency.nrchealth.com/widget/api/org-profile/the-carle-foundation/npi/${this.provider.Npi}/200`,
                crossDomain: true,
                dataType: 'jsonp',
                jsonp: 'jsonp',
            });
            if (ratings.valid) {
                this.ratings = ratings;
            }
            $('.a-btn--video').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-fade',
                preloader: true,
            });
            document.title = `${this.fullName} | Carle.org`;
        },
    };
</script>