import { render, staticRenderFns } from "./Facebook.vue?vue&type=template&id=2aee4252&"
import script from "./Facebook.vue?vue&type=script&lang=js&"
export * from "./Facebook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\inetpub\\wwwroot\\Carle-New-CHPP\\Carle-New-CHPP\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('2aee4252', component.options)
    } else {
      api.reload('2aee4252', component.options)
    }
    module.hot.accept("./Facebook.vue?vue&type=template&id=2aee4252&", function () {
      api.rerender('2aee4252', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Assets/VueComponents/Social/Facebook.vue"
export default component.exports