<template>
    <li class="facebook">
        <a href="#" @click.prevent="openUrl">
            <i class="fa fa-facebook-square" aria-hidden="true"></i>
            <span class="show-for-sr">Link to share via Facebook</span>
        </a>
    </li>
</template>

<script type="text/javascript">
    export default {
        name: 'Facebook',
        props: {
            appid: {
                type: String,
                required: true,
            },
            display: {
                type: String,
                default: 'popup',
            },
            link: {
                type: String,
                default: window.location.href,
            },
            redirect: {
                type: String,
                default: window.location.href,
            },
        },
        computed: {
            shareUrl() {
                return `https://www.facebook.com/dialog/feed?app_id=${this.appid}&display=${this.display}&link=${encodeURIComponent(this.link)}&redirect_uri=${this.redirect}`;
            }
        },
        methods: {
            openUrl(e) {
                this.$parent.$emit('open', this.shareUrl, 'facebook');
            },
        },
    };
</script>