<template>
    <Popover controls-element="waitTimesPanel"
             panel-label=""
             close-label="Close Wait Times Panel"
             v-if="shortest">
        <template v-slot:button>
            <img src="/Build/Images/Fap/icons/a-icon_clock.svg" alt="Clock Icon">
            <span>Shortest Wait - </span><strong>{{ shortest.LocationName }}</strong>
        </template>
        <template v-slot:default>
            <div class="grid-x grid-margin-x">
                <div class="small-12 large-6 cell" style="border-right: 1px solid #555559; padding-right: 24px;">
                    <p><strong>Convenient Care - Shortest Wait</strong></p>
                    <ol class="shortest-waits">
                        <li class="shortest-waits__location" v-for="location in nonEmergency">
                            <a :href="`/locations/${location.LocationInfo.Slug}`">
                                <strong>{{ location.LocationInfo.Title }}</strong>
                            </a>
                            <em> - {{ getMinutes(location) }}</em><br>
                            <a :href="`https://google.com/maps/dir/?api=1&destination=${location.LocationInfo.Latitude},${location.LocationInfo.Longitude}`"
                               target="_blank"
                               class="a-external-link">
                                <strong>Get Directions</strong> <span class="sr-only">(opens in new window)</span>
                            </a>
                        </li>
                    </ol>
                </div>
                <div class="small-12 large-6 cell">
                    <p><strong>Emergency Care - Shortest Wait</strong></p>
                    <ol class="shortest-waits">
                        <li class="shortest-waits__location" v-for="location in emergency">
                            <a :href="`/locations/${location.LocationInfo.Slug}`">
                                <strong>{{ location.LocationInfo.Title }}</strong>
                            </a>
                            <em> - {{ getMinutes(location) }}</em><br>
                            <a :href="`https://google.com/maps/dir/?api=1&destination=${location.LocationInfo.Latitude},${location.LocationInfo.Longitude}`"
                               target="_blank"
                               class="a-external-link">
                                <strong>Get Directions</strong> <span class="sr-only">(opens in new window)</span>
                            </a>
                        </li>
                    </ol>
                </div>
            </div>
            
        </template>
    </Popover>
</template>

<style lang="scss" scoped>
    .a-open-panel{
        > img {
            position: relative;
            top: -1px;
        }
        > span {
            color: #555559;
        }
    }
    .shortest-waits{
        list-style: none;
        margin: 0;

        &__location {
            margin: 0 0 1rem;

            .a-external-link{
                font-size: 0.875rem;
            }
        }
    }
</style>

<script type="text/javascript">
    import $ from 'jQuery';
    import Moment from 'moment-timezone';
    import Popover from './Popover.vue';
    export default {
        name: 'WaitTime',
        components: {
            Popover
        },
        data() {
            return {
                locations: [],
            };
        },
        computed: {
            nonEmergency() {
                return this.locations.filter(l => l.DepartmentType < 3);
            },
            emergency() {
                return this.locations.filter(l => l.DepartmentType === 3);
            },
            shortest() {
                if (!this.locations.length) {
                    return false;
                }
                return this.nonEmergency[0] || this.locations[0];
            }
        },
        methods: {
            getMinutes(location) {
                const now = Moment().tz('America/Chicago');
                const expires = Moment.tz(location.CreatedOn, 'America/Chicago').add(30, 'minutes');

                if (now.isBefore(expires)) {
                    return `${location.WaitTimeMinutes} min.`;
                }
                return 'Wait Time Unknown';
            },
        },
        async mounted() {
            this.locations = await $.get(`${window.mvcVars.config.FapUrl}/WaitTime`);
        }
    }
</script>