<template>
    <div class="m-api">
        <div class="m-api__result">
            <slot :result="result" :displayname="displayname" :noresult="noresult" :loading="loading" :error="error" :letters="letters" :letterClick="letterClick"></slot>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    export default {
        name: 'AlphaSelector',
        props: {
            endpoint: String,
            pagetype: String,
            pagetypedisplayname: String,
            auth: String,
        },
        data() {
            return {
                loading: false,
                displayname: this.pagetypedisplayname,
                letters: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
                result: null,
                error: null,
                noresult: null,
            };
        },
        methods: {
            letterClick: function (event) {
                var vm = this;

                $(".alpha-box").removeClass("selected");
                $(event.target).addClass("selected");

                $.ajax({
                    url: vm.endpoint + vm.pagetype + "&orderby=DocumentName ASC&Where=DocumentName LIKE '" + event.target.text + "%'",
                    type: 'GET',
                    dataType: 'json',
                    beforeSend: (xhr) => {
                        xhr.setRequestHeader('Authorization', 'Basic ' + this.auth);
                        vm.loading = true;
                        vm.noresult = false;
                    },
                    success: (r) => {
                        vm.$emit('success', r);
                        vm.result = r;
                        if (r.cms_documents[0].carle_service == undefined && r.cms_documents[0].carle_condition == undefined) {
                            vm.noresult = true;
                        }

                        return vm.data;
                    },
                    error: (e, msg) => {
                        vm.$emit('error', e);
                        vm.error = e;
                    }

                }).done(function () {
                    vm.loading = false;
                });
            },
        },
    };
</script>