var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "autocomplete",
    attrs: { type: "text", placeholder: _vm.placeholder }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }