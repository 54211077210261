export function arrayChunk(arr, chunkSize) {
    if (!arr.length) {
        return [[]];
    }
    const results = [];
    const tmpArr = arr.slice(0);

    while (tmpArr.length) {
        results.push(tmpArr.splice(0, chunkSize));
    }

    return results;
}
export function escapeRegex(str) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function getUserPosition(fallback) {
    return new Promise((resolve, reject) => {
        if (!('geolocation' in navigator)) {
            reject(fallback);
        }
        navigator.geolocation.getCurrentPosition(position => resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        }), () => reject(fallback));
    });
}

export default {
    arrayChunk,
};