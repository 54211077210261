var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shortest
    ? _c("Popover", {
        attrs: {
          "controls-element": "waitTimesPanel",
          "panel-label": "",
          "close-label": "Close Wait Times Panel"
        },
        scopedSlots: _vm._u(
          [
            {
              key: "button",
              fn: function() {
                return [
                  _c("img", {
                    attrs: {
                      src: "/Build/Images/Fap/icons/a-icon_clock.svg",
                      alt: "Clock Icon"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Shortest Wait - ")]),
                  _c("strong", [_vm._v(_vm._s(_vm.shortest.LocationName))])
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _c("div", { staticClass: "grid-x grid-margin-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "small-12 large-6 cell",
                        staticStyle: {
                          "border-right": "1px solid #555559",
                          "padding-right": "24px"
                        }
                      },
                      [
                        _c("p", [
                          _c("strong", [
                            _vm._v("Convenient Care - Shortest Wait")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "ol",
                          { staticClass: "shortest-waits" },
                          _vm._l(_vm.nonEmergency, function(location) {
                            return _c(
                              "li",
                              { staticClass: "shortest-waits__location" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/locations/" +
                                        location.LocationInfo.Slug
                                    }
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(location.LocationInfo.Title)
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("em", [
                                  _vm._v(
                                    " - " + _vm._s(_vm.getMinutes(location))
                                  )
                                ]),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "a-external-link",
                                    attrs: {
                                      href:
                                        "https://google.com/maps/dir/?api=1&destination=" +
                                        location.LocationInfo.Latitude +
                                        "," +
                                        location.LocationInfo.Longitude,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("strong", [_vm._v("Get Directions")]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("(opens in new window)")
                                    ])
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "small-12 large-6 cell" }, [
                      _c("p", [
                        _c("strong", [_vm._v("Emergency Care - Shortest Wait")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "ol",
                        { staticClass: "shortest-waits" },
                        _vm._l(_vm.emergency, function(location) {
                          return _c(
                            "li",
                            { staticClass: "shortest-waits__location" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/locations/" + location.LocationInfo.Slug
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(location.LocationInfo.Title))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("em", [
                                _vm._v(" - " + _vm._s(_vm.getMinutes(location)))
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "a-external-link",
                                  attrs: {
                                    href:
                                      "https://google.com/maps/dir/?api=1&destination=" +
                                      location.LocationInfo.Latitude +
                                      "," +
                                      location.LocationInfo.Longitude,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c("strong", [_vm._v("Get Directions")]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("(opens in new window)")
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          2488549575
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }