import 'slick-carousel';
import 'magnific-popup';
import 'foundation-sites';
import "@/Styles/main.scss";
import $ from 'jquery';
import Vue from 'vue';
import components from '@/VueComponents';

$(function () {

    $(document).foundation();

    const $win = $(window);

    const $headerEntry = $('.m-header-entry');
    const $navAlt = $('.m-header-bar .m-nav-alt');
    const $nav = $('.m-nav');
    const $navSecondary = $('.m-nav-secondary');
    const $navTrigger = $('.nav-trigger');
    const $searchTrigger = $('.search-trigger');
    const $header = $('.o-header');
    const $search = $('.o-header .m-search');
    const $sliderCards = $('.m-slider-cards .a-slider__slides');


    const $radio = $('.map__info input[name="maptype"]');
    //const $maps = ["https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d12204.528548757116!2d-88.22439900384383!3d40.11705865999303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sRestaurants!5e0!3m2!1sen!2str!4v1569462563547!5m2!1sen!2str",
    //    "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d12203.879902964312!2d-88.22358371210633!3d40.12067257666811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1shotels!5e0!3m2!1sen!2str!4v1569596240587!5m2!1sen!2str"];

    // tablet fix for featured area:

    const $featuredAreaContent = $('.m-feature-media .large-4');

    $radio.on('change', function () {

        initMapFoodLodging(parseInt($(this).val()));
        //$("#foodlodgingmap").attr("src", $maps[parseInt($(this).val())]);
    });

    $(".thank-you-carle-doctors select").remove();
    var date = new Date();
    date = date.toISOString();
    $('input[placeholder="Date"]').attr('value', date);

    $navSecondary.clone().addClass('js-clone').attr("id", "mobile-nav").attr("aria-expanded", "false").prependTo($headerEntry);
    $navAlt.clone().addClass('js-clone').appendTo($headerEntry);
    $nav.clone().addClass('js-clone').appendTo($headerEntry);
    $navAlt.clone().addClass('js-clone-alt').appendTo($headerEntry);

    $('.search.search--header').clone().addClass('js-clone').prependTo(".m-header-chpp-nav-holder");
    $('.nav-user-chpp').clone().addClass('js-clone').prependTo(".m-header-chpp-nav-holder");
    $('.m-header-chpp-content .a-btn-inverse').clone().addClass('js-clone').appendTo(".m-header-chpp-nav-holder");
    $('.m-header-chpp-content .a-btn-main').clone().addClass('js-clone').appendTo(".m-header-chpp-nav-holder");

    $navTrigger.on('click', function (event) {
        event.preventDefault();

        $(this).toggleClass('nav-trigger--active');
        $('body').toggleClass('fixed');

        $header.find('.m-header-entry').slideToggle();

        $("#mobile-nav").attr("aria-expanded", eval("$('#mobile-nav').attr('aria-expanded')") == "false" ? "true" : "false");
        $(this).attr("aria-label", eval("$(this).attr('aria-label')") == "Menu" ? "Close Menu" : "Menu");

        $('.m-header-chpp-nav-holder').slideToggle();
    });

    var eventDetailsHeight = 0;
    $(".o-events-widget .m-event-card_header").each(function () {
        eventDetailsHeight = eventDetailsHeight > $(this).height() ? eventDetailsHeight : $(this).height();
    });

    $searchTrigger.on('click', function (event) {
        event.preventDefault();

        $(this).toggleClass('search-trigger--active');

        $search.slideToggle();

        if ($navTrigger.hasClass('nav-trigger--active')) {
            $navTrigger.removeClass('nav-trigger--active');
            $('body').removeClass('fixed');
            $header.find('.m-header-entry').slideUp();
        }
    });

    // Homepage alert mobile

    $(".caret-button").click(function(e) {
        if ($(this).hasClass("fa-caret-down")) {
            $(".emergency-alert").slideToggle();
            $(this).removeClass("fa-caret-down");
            $(this).addClass("fa-caret-up");
        } else {
            $(".emergency-alert").slideToggle();
            $(this).removeClass("fa-caret-up");
            $(this).addClass("fa-caret-down");
        }
    });

    // Tabs

    function tabs() {
        const $tabNavs = $('.a-tabs__nav');
        const $tabPrev = $('.a-tab-prev');
        const $tabNext = $('.a-tab-next');



        $tabNext.on('click', function (event) {
            event.preventDefault();
            $tabPrev.attr("disabled", false);
            $tabPrev.attr("aria-label", "Switch to " + $("#" + $tabNavs.find("button[aria-selected='true']").attr("aria-controls") + " h3").text() + " tab");

            $tabNavs.find("button[aria-selected='true']").next("button").trigger("click");
            if ($tabNavs.find("button[aria-selected='true']").next("button").length == 0) {

                $(this).attr("disabled", true);
                $(this).attr("aria-label", "Last tab");

            }
            else {

                $(this).attr("aria-label", "Switch to " + $("#" + $tabNavs.find("button[aria-selected='true']").next("button").attr("aria-controls") + " h3").text() + " tab");


            }


        });

        $tabPrev.on('click', function (event) {
            event.preventDefault();
            $tabNext.attr("disabled", false);

            $tabNext.attr("aria-label", "Switch to " + $("#" + $tabNavs.find("button[aria-selected='true']").attr("aria-controls") + " h3").text() + " tab");


            $tabNavs.find("button[aria-selected='true']").prev("button").trigger("click");
            if ($tabNavs.find("button[aria-selected='true']").prev("button").length == 0) {

                $(this).attr("disabled", true);
                $(this).attr("aria-label", "First tab");

            }
            else {

                $(this).attr("aria-label", "Switch to " + $("#" + $tabNavs.find("button[aria-selected='true']").prev("button").attr("aria-controls") + " h3").text() + " tab");


            }

        });
    }



    tabs();

    function showHideList() {
        const $listParent = $('.o-section-awards .a-section-awards__entry');
        let $button = $('.a-section-awards__actions .a-btn-main')

        $button.on('click', function (event) {
            event.preventDefault();
            $button = $(this);
            $listParent.slideToggle();
            let $buttonText = $button.find('span').text() == "SEE LESS" ? ($button.find('span').text("SEE MORE"), $button.removeClass('more')) : ($button.find('span').text("SEE LESS"), $button.addClass('more'));
        });
    }

    showHideList();

    //Looking for dropdown

    $('.m-nav-secondary li').each(function () {
        let $this = $(this);
        let hasDropdown = $this.find('ul').length;

        if (hasDropdown ) {
            $this.addClass('has-dd');
        }
    });




    var megaMenuBehaviour = function (event) {

        if ($win.width() < 1024) return;

        let $this = $(this);

        if ((event.type == "click")) {


            $this.closest('li').toggleClass('open')
                .siblings('li')
                .removeClass('open');



            $this.closest('li').siblings('li').children('button').attr("aria-expanded", "false");

            $this.attr("aria-expanded", eval("$this.attr('aria-expanded')") == "false" ? "true" : "false");


        }





        else if (((event.type == 'mouseenter') && !$this.closest('li').hasClass('open')) || event.type == "mouseleave") {

            $this
                .toggleClass('open')
                .siblings('li')
                .removeClass('open');

            $this.siblings('li').children('button')
                .attr("aria-expanded", "false");

            $this.children('button').attr("aria-expanded", eval("$this.children('button').attr('aria-expanded')") == "false" ? "true" : "false");




        }


        if ((event.type == 'mouseenter') && $this.closest('li').hasClass('open'))  {

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'mega-menu-hover',
                'top-menu-item': $this.children("button").text().trim()
            });

        }


    }

    $('.m-nav-secondary .has-dd').hover(megaMenuBehaviour, megaMenuBehaviour);
    $('.m-nav-secondary .has-dd > button').focus(megaMenuBehaviour);
    $(":not('.m-nav-secondary *')").focus(function() { $('.m-nav-secondary .has-dd').removeClass("open") });

    // Toggle dropdown nav
    $(".m-nav-secondary li.has-dd > h2 button").on("click", function (event) {
        let $this = $(this);
        const $dropDown = $('.m-dropdown');
        event.preventDefault();
        if ($win.width() < 1024) {

            $this.closest("h2").siblings($dropDown).slideToggle();
            $this.closest('li').siblings('li').find($dropDown).slideUp();
            $this.closest('li').toggleClass('open')
                .siblings('li')
                .removeClass('open');

            $this.closest('li').siblings('li').children('button')
                .attr("aria-expanded", "false");

            $this.attr("aria-expanded", eval("$this.attr('aria-expanded')") == "false" ? "true" : "false");

        }
        else {

            if ($this.is(":hover")) {
                window.location = $(this).attr("data-target");
            }
            else {

                megaMenuBehaviour.call($this, event);

            }
        }
    })

    $(".m-nav-secondary li.has-dd > button").on("mousedown", function (event) {
        event.preventDefault();

    })

    $('.m-nav a').on('focus', function (event) {

        const $this = $(this);
        if ($win.width() > 1024) {
            event.preventDefault();
            $this.parents("li").addClass("hover");
            //$this.toggleClass('active').siblings('ul').slideToggle().closest('.has-dd').siblings('.has-dd').find('ul').slideUp().siblings('a').removeClass('active');
            $this.parents("li").siblings("li").removeClass("hover");
        }
    });

    $(":not('.m-nav a')").focus(function () { $('.m-nav li').removeClass("hover") });

    $(document).on('click', function (e) {
        const container = $('.m-nav-secondary');

        if ($win.width() > 1024) {
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $('.m-nav-secondary').find('.open').removeClass('open');
            }
        }
    });

    $('.o-play,  .o-play-alt').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        preloader: true,
    });

    $('.alert-popup').magnificPopup({
        type: 'inline',
        mainClass: 'mfp-fade',
        preloader: true,
        midClick: true,
        closeMarkup: '<button title="%title%" type="button" class="mfp-close">CLOSE x</button>'
    });

    $('.hero-video').magnificPopup({
        type: 'inline',
        mainClass: 'mfp-fade',
        preloader: true,
        midClick: true
    });

    $('.form-popup').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        preloader: true,
    });


    var scrollFunc = () => {

        if ($win.width() >= 1024) {
            let originalPadding = "22px 0 16px";
            $(".m-header-content .m-search--header").css("flex", "");  //to avoid issues when resizing
            if ($(document).scrollTop() > 200) {
                $(".m-header-entry").css("transform", "scaleY(0)");
                $(".m-header-entry").css("height", "0");
                $(".m-header-entry").css("padding", "0");
                //$(".m-header-entry").css("transition", "transform .3s ease, height 0.3s ease,  padding 0.3s ease");

            }
            else {
                $(".m-header-entry").css("transform", "");
                $(".m-header-entry").css("display", "");
                $(".m-header-entry").css("height", "auto");
                $(".m-header-entry").css("padding", originalPadding);

            }
        }
        else {
            let originalPadding = "13px";
            if ($(document).scrollTop() > 200) {
                $(".m-header-content .m-search--header").css("flex", "0 0 89%");
                $(".m-header-content .o-font-settings").css("display", "none");
                $(".m-header-content .a-logo").css("display", "none");
                //$(".m-search").css("transition", "transform .3s ease,  padding 0.3s ease");
                $(".m-header-content").css("padding-top", "0");
                $(document.body).css("margin-top", $("header").height());
            }
            else {
                $(".m-header-content .m-search--header").css("flex", "0 0 100%");
                $(".m-header-content .o-font-settings").css("display", "");
                $(".m-header-content .a-logo").css("display", "");
                //$(".m-search").css("transition", "transform .3s ease,  padding 0.3s ease");
                $(".m-header-content").css("padding-top", originalPadding);
                $(document.body).css("margin-top", $("header").height());
            }

        }
    }

    $(window).resize(() => {
        $(document.body).css("margin-top", $("header").height());
        scrollFunc();
    }).resize();

    $(window).scroll(scrollFunc);


    $('.vue-container').each((i, el) => {
        new Vue({ el, components });
    });

    $('.tab-button').click(function () {
        var id = $(this).attr("data-id");

        $('.tab-button').removeClass('active');
        $('.tab').removeClass('active');

        $('#' + id).addClass('active');
        $(this).addClass('active');
    });

    $.each($('body').attr('class').split(/\s+/), (index, item) => {
        // This loads a dynamic webpack chunk based on the body class
        import(/* webpackChunkName: "[request]" */ `./pages/${item}`).catch((e) => { });
    });

    $('.m-feature--accordion .e-feature__head').on('click', function (event) {
        event.preventDefault();

        const $this = $(this);
        $this.toggleClass('active');
        $this.closest('.m-feature--accordion').find('.a-feature-content').slideToggle().closest('.a-tab ').siblings('.a-tab').find('.m-feature--accordion .e-feature__head').removeClass('active').closest('.m-feature--accordion').find('.a-feature-content').slideUp();

    });

    // Body font size 1px increase/decrease
    const FontSizeStep = 1;
    // Maximum body font size
    const MaxFontSize = 32;
    // Minimum body font size
    const MinFontSize = 16;
    const $html = $(document.documentElement);
    const $body = $(document.body);
    const $btnFontSizeDecrease = $('.font-size-decrease');
    const $btnFontSizeIncrease = $('.font-size-increase');

    //Convert Pixel to Integer
    function convertPixelValueToInteger(pixelValue) {
        return parseInt(pixelValue.replace('px', ''), 10);
    }

    // Set Font Size
    function setFontSize($element, amount) {
        let currentFontSize = convertPixelValueToInteger(
            $element.css('font-size')
        );

        let newFontSize = currentFontSize + amount;

        if (newFontSize < MinFontSize || newFontSize > MaxFontSize) {
            return;
        }

        $element.css('font-size', newFontSize);

        if (newFontSize >= 15) {
            $('.wrapper').addClass('full');
        } else {
            $('.wrapper').removeClass('full');
        }
    }

    // Reset Font Size
    function resetFontSize(event) {
        if (event.ctrlKey && event.keyCode === 96) {
            $html.removeAttr('style');
        }
    }

    // Decreases font size
    $btnFontSizeIncrease.on('click', function (event) {
        event.preventDefault();

        setFontSize($html, FontSizeStep);
        setFontSize($body, FontSizeStep);
    });

    // Increases font size
    $btnFontSizeDecrease.on('click', function (event) {
        event.preventDefault();

        setFontSize($html, -1 * FontSizeStep);
        setFontSize($body, -1 * FontSizeStep);
    });
});

$(document).ready(function () {

    $('.m-nav-chpp li').each(function() {
        let $this = $(this);
        let hasDropdown = $this.find('ul').length;

        if (hasDropdown) {
            $this.addClass('has-dd');
        }
    });

    $('.m-nav-chpp .has-dd').on('click', 'a', function(event) {
        if ($(window).width() >= 1024) {
            return
        }

        event.preventDefault();

        const $this = $(this);

        $this.closest('li').toggleClass('active').find('ul').slideToggle();
    });

    $(document).keydown(function(e) {
        var code = e.keyCode || e.which;

        if ($(e.target).closest('.m-nav-chpp')) {
            $(e.target)
                .closest('li')
                .addClass('is-open')
                .siblings('li')
                .removeClass('is-open');

            console.log($(e.target).closest('li'));
        }
    });

    const $featuredAreaContent = $('.m-feature-media .large-4');

    $('.carle-slick-slider').slick({
        dots: true,
        infinite: true,
        centerMode: true,
        arrows: true,
        appendDots: $(".slick-dots-wrap"),
        prevArrow: $(".slider-prev"),
        nextArrow: $(".slider-next"),
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.gallery-item-1').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('.gallery-item-2').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    if ($(window).width() >= 1024 && $(window).width() <= 1400) {

        if ($featuredAreaContent) {


            $featuredAreaContent.removeClass("large-4").addClass("large-5");
        }

    }
    setTimeout(function () { $(document.body).css("margin-top", $("header").height()); }, 500);
    //Accessibility improvements

    $("i.fa.fa-external-link").attr("aria-hidden", "true");
    $("i.fa.fa-external-link").closest("a").attr("title", "Link navigates to an external site");
    $("i.fa.fa-play").attr("aria-label", "link launches a video").attr("aria-hidden", "false");
    $("footer li a").each(function (el) { $(this).attr("aria-label", $.trim($(this).closest(".a-footer__links").children("span:first-of-type").text() + ": " + $(this).text())) })


    $(".m-nav-secondary.js-clone").find(".m-dropdown").each(function () {
        $(this).attr("id", $(this).attr("id") + "_mobile");
        $(this).siblings("button").attr("aria-controls", $(this).attr("id") );
        //Below code is needed because flex-direction:row-reverse class is not ADA-compliant and had to be eliminated from code.
        $(this).find(".m-dropdown__content").prependTo($(this).find(".a-dropdown__inner"))
    })

    $("section").each(function () {
        if ($(this).attr('aria-label')) {
            return;
        }
        var ariaLabel;
        if ($(this).find(".a-title-underline").length > 0) {
            ariaLabel = $.trim($(this).find(".a-title-underline").eq(0).text());
        }
        else if ($(this).find("h1").length > 0) {
            ariaLabel = $.trim($(this).find("h1").eq(0).text());
        }
        else if ($(this).find("h2").length > 0) {
            ariaLabel = $.trim($(this).find("h2").eq(0).text());
        }
        else if ($(this).find("h3").length > 0) {
            ariaLabel = $.trim($(this).find("h3").eq(0).text());
        }

        $(this).attr("aria-label", ariaLabel);
    });

    $(".a-btn-main, .a-btn-inverse").keydown(function (event) {
        if (event.which == 32) {
            event.preventDefault();
            if ($(this).attr("target") == "_blank") {
                window.open($(this).attr("href"), "_blank");

            }
            else {
                window.location = $(this).attr("href");
            }
        }


    });

    // set cookie for home alerts

    $("div[data-magnific-title]").each(function () {
        var alertTitle = $(this).attr("data-magnific-title");
        if (Cookies.get(alertTitle) !== '' && Cookies.get(alertTitle) !== undefined) {
            //do nothing
        } else {
            $(".alert-popup").magnificPopup('open');
            Cookies.set(alertTitle, 'true', { expires: 7 });
        }
    });

    // dynamic url selection for button

    $("body").on("change", ".url-list", function () {
        var selectedUrl = $(".url-list").val();

        if (selectedUrl != "") {
            $(".url-selector-btn").attr("disabled", false);
        } else {
            $(".url-selector-btn").attr("disabled", "disabled");
        }
    });

    $(".url-selector-btn").click(function (e) {
        var selectedUrl = $(".url-list").val();

        if ($(this).hasClass("a-external-link")) {
            if (selectedUrl == "") {
                e.preventDefault();
            } else {
                window.open(selectedUrl, "_blank");
            }
        } else {
            if (selectedUrl == "") {
                e.preventDefault();
            } else {
                window.location = selectedUrl;
            }
        }
    });


});

/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/
(function () {
    var tablist = document.querySelectorAll('[role="tablist"]');

    if (!tablist.length) {
        return false;
    }

    tablist = tablist[0];
    var tabs;
    var panels;
    var delay = determineDelay();

    generateArrays();

    function generateArrays() {
        tabs = document.querySelectorAll('[role="tab"]');
        panels = document.querySelectorAll('[role="tabpanel"]');
    };

    // For easy reference
    var keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46
    };

    // Add or substract depending on key pressed
    var direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1
    };

    // Bind listeners
    for (var i = 0; i < tabs.length; ++i) {
        addListeners(i);
    };

    function addListeners(index) {
        tabs[index].addEventListener('click', clickEventListener);
        tabs[index].addEventListener('keydown', keydownEventListener);
        tabs[index].addEventListener('keyup', keyupEventListener);

        // Build an array with all tabs (<button>s) in it
        tabs[index].index = index;
    };

    // When a tab is clicked, activateTab is fired to activate it
    function clickEventListener(event) {
        var tab = event.target;
        activateTab(tab, false);
    };

    // Handle keydown on tabs
    function keydownEventListener(event) {
        var key = event.keyCode;

        switch (key) {
            case keys.end:
                event.preventDefault();
                // Activate last tab
                activateTab(tabs[tabs.length - 1]);
                break;
            case keys.home:
                event.preventDefault();
                // Activate first tab
                activateTab(tabs[0]);
                break;

            // Up and down are in keydown
            // because we need to prevent page scroll >:)
            case keys.up:
            case keys.down:
                determineOrientation(event);
                break;
        };
    };

    // Handle keyup on tabs
    function keyupEventListener(event) {
        var key = event.keyCode;

        switch (key) {
            case keys.left:
            case keys.right:
                determineOrientation(event);
                break;
            case keys.delete:
                determineDeletable(event);
                break;
        };
    };

    // When a tablistâ€™s aria-orientation is set to vertical,
    // only up and down arrow should function.
    // In all other cases only left and right arrow function.
    function determineOrientation(event) {
        var key = event.keyCode;
        var vertical = tablist.getAttribute('aria-orientation') == 'vertical';
        var proceed = false;

        if (vertical) {
            if (key === keys.up || key === keys.down) {
                event.preventDefault();
                proceed = true;
            };
        }
        else {
            if (key === keys.left || key === keys.right) {
                proceed = true;
            };
        };

        if (proceed) {
            switchTabOnArrowPress(event);
        };
    };

    // Either focus the next, previous, first, or last tab
    // depening on key pressed
    function switchTabOnArrowPress(event) {
        var pressed = event.keyCode;

        for (var x = 0; x < tabs.length; x++) {
            tabs[x].addEventListener('focus', focusEventHandler);
        };

        if (direction[pressed]) {
            var target = event.target;
            if (target.index !== undefined) {
                if (tabs[target.index + direction[pressed]]) {
                    tabs[target.index + direction[pressed]].focus();
                }
                else if (pressed === keys.left || pressed === keys.up) {
                    focusLastTab();
                }
                else if (pressed === keys.right || pressed == keys.down) {
                    focusFirstTab();
                };
            };
        };
    };

    // Activates any given tab panel
    function activateTab(tab, setFocus) {
        setFocus = setFocus || true;
        // Deactivate all other tabs
        deactivateTabs();

        // Remove tabindex attribute
        tab.removeAttribute('tabindex');

        // Set the tab as selected
        tab.setAttribute('aria-selected', 'true');

        // Get the value of aria-controls (which is an ID)
        var controls = tab.getAttribute('aria-controls');

        // Remove hidden attribute from tab panel to make it visible
        document.getElementById(controls).removeAttribute('hidden');

        // Set focus when required
        if (setFocus) {
            tab.focus();
        };
    };

    // Deactivate all tabs and tab panels
    function deactivateTabs() {
        for (var t = 0; t < tabs.length; t++) {
            tabs[t].setAttribute('tabindex', '-1');
            tabs[t].setAttribute('aria-selected', 'false');
            tabs[t].removeEventListener('focus', focusEventHandler);
        };

        for (var p = 0; p < panels.length; p++) {
            panels[p].setAttribute('hidden', 'hidden');
        };
    };

    // Make a guess
    function focusFirstTab() {
        tabs[0].focus();
    };

    // Make a guess
    function focusLastTab() {
        tabs[tabs.length - 1].focus();
    };

    // Detect if a tab is deletable
    function determineDeletable(event) {
        var target = event.target;

        if (target.getAttribute('data-deletable') !== null) {
            // Delete target tab
            deleteTab(event, target);

            // Update arrays related to tabs widget
            generateArrays();

            // Activate the closest tab to the one that was just deleted
            if (target.index - 1 < 0) {
                activateTab(tabs[0]);
            }
            else {
                activateTab(tabs[target.index - 1]);
            };
        };
    };

    // Deletes a tab and its panel
    function deleteTab(event) {
        var target = event.target;
        var panel = document.getElementById(target.getAttribute('aria-controls'));

        target.parentElement.removeChild(target);
        panel.parentElement.removeChild(panel);
    };

    // Determine whether there should be a delay
    // when user navigates with the arrow keys
    function determineDelay() {
        var hasDelay = tablist.hasAttribute('data-delay');
        var delay = 0;

        if (hasDelay) {
            var delayValue = tablist.getAttribute('data-delay');
            if (delayValue) {
                delay = delayValue;
            }
            else {
                // If no value is specified, default to 300ms
                delay = 300;
            };
        };

        return delay;
    };

    //
    function focusEventHandler(event) {
        var target = event.target;

        setTimeout(checkTabFocus, delay, target);
    };

    // Only activate tab on focus if it still has focus after the delay
    function checkTabFocus(target) {
        var focused = document.activeElement;

        if (target === focused) {
            activateTab(target, false);
        };
    };
}());



