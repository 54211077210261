<template>
    <ul class="social-icons">
        <slot></slot>
    </ul>
</template>

<style lang="scss">
    ul.social-icons {
        list-style: none;
        margin: 0;

        li {
            display: inline-block;
            font-size: 2rem;
            margin-right: 4px;
        }
    }
</style>

<script type="text/javascript">
    export default {
        name: 'LinkedIn',
        props: {
            toolbar: {
                type: String,
                default: 'no',
            },
            location: {
                type: String,
                default: 'no',
            },
            status: {
                type: String,
                default: 'no',
            },
            menubar: {
                type: String,
                default: 'no',
            },
            scrollbars: {
                type: String,
                default: 'yes',
            },
            resizable: {
                type: String,
                default: 'yes',
            },
            width: {
                type: [Number, String],
                default: 640,
            },
            height: {
                type: [Number, String],
                default: 480,
            },
        },
        computed: {
            windowProps() {
                return [
                    `toolbar=${this.toolbar}`,
                    `location=${this.location}`,
                    `status=${this.status}`,
                    `menubar=${this.menubar}`,
                    `scrollbars=${this.scrollbars}`,
                    `resizable=${this.resizable}`,
                    `width=${this.width}`,
                    `height=${this.height}`,
                ].join(',');
            }
        },
        methods: {
            open(url, ctx = null) {
                window.open(url, ctx, this.windowProps);
            }
        },
        mounted() {
            this.$on('open', this.open);
        }
    };
</script>