var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "root" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-3 col-md-12" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "selected-media mb-4" }, [
              _c("div", [
                _c("h4", [_vm._v("Selected")]),
                _vm._v(" "),
                _vm.selectedMediaUrl
                  ? _c("img", { attrs: { src: _vm.selectedMediaFullUrl } })
                  : _c("span", [_vm._v("No media selected")])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary btn-sm",
                  on: {
                    click: function($event) {
                      _vm.isLibraryVisible = !_vm.isLibraryVisible
                    }
                  }
                },
                [
                  !_vm.isLibraryVisible
                    ? _c("span", [_vm._v("Show Library")])
                    : _c("span", [_vm._v("Hide Library")])
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.isLibraryVisible
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.onSearch($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Page Size")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.pageSize,
                            expression: "params.pageSize"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number" },
                        domProps: { value: _vm.params.pageSize },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.params,
                              "pageSize",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Page Number")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.pageNumber,
                            expression: "params.pageNumber"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number" },
                        domProps: { value: _vm.params.pageNumber },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.params,
                              "pageNumber",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Title")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fileTitle,
                            expression: "params.fileTitle"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.params.fileTitle },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.params,
                              "fileTitle",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Filename")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fileName,
                            expression: "params.fileName"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.params.fileName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.params,
                              "fileName",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm._m(0)
                  ]
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.isLibraryVisible
        ? _c("div", { staticClass: "col-lg-9 col-md-12" }, [
            _c("h4", [_vm._v("Available")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-desk row media-gallery flex-wrap" },
              [
                _vm._l(_vm.medias, function(media) {
                  return [
                    _c(
                      "div",
                      {
                        key: media.fileGUID,
                        staticClass: "col-lg-3 col-md-6 mb-4"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card border border-secondary rounded h-100"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center card-img-top"
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    id: media.fileID,
                                    src: _vm.getMediaUrl(media),
                                    alt: media.fileTitle
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onDisplay(media)
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "content" }, [
                              _c("div", { staticClass: "card-body" }, [
                                _c(
                                  "h5",
                                  { staticClass: "card-title text-secondary" },
                                  [_vm._v(_vm._s(media.fileTitle))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "card-text text-dark" },
                                  [_vm._v(_vm._s(media.fileName))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-outline-primary btn-sm",
                                    on: {
                                      click: function($event) {
                                        return _vm.onSelect(media)
                                      }
                                    }
                                  },
                                  [_vm._v("Select")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "card-footer" }, [
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    _vm._s(media.fileImageWidth) +
                                      "x" +
                                      _vm._s(media.fileImageHeight) +
                                      "px"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(_vm._s(media.fileSize / 1000) + " kb")
                                ])
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.modalMedia
      ? _c(
          "div",
          {
            staticClass: "modal",
            class: { "d-block": _vm.modalMedia },
            attrs: { tabindex: "-1", role: "dialog" }
          },
          [
            _c(
              "div",
              { staticClass: "modal-dialog", attrs: { role: "document" } },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c("h5", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.modalMedia.fileTitle))
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          "aria-label": "Close"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: { "aria-hidden": "true" },
                            on: {
                              click: function($event) {
                                return _vm.onDisplay(undefined)
                              }
                            }
                          },
                          [_vm._v("×")]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("img", {
                      attrs: {
                        id: _vm.modalMedia.fileID,
                        src: _vm.getMediaUrl(_vm.modalMedia, 400),
                        alt: _vm.modalMedia.fileTitle
                      },
                      on: {
                        click: function($event) {
                          return _vm.onDisplay(undefined)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.onSelect(_vm.modalMedia)
                          }
                        }
                      },
                      [_vm._v("Select")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: {
                          click: function($event) {
                            return _vm.onDisplay(undefined)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        Close\n                    "
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Search")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }