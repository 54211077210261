var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "mail" }, [
    _c("a", { attrs: { href: _vm.shareUrl } }, [
      _c("i", {
        staticClass: "fa fa-envelope-square",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "show-for-sr" }, [
        _vm._v("Link to share via email")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }