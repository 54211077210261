<template>
    <nav aria-label="Pagination">
        <ul class="pagination" v-if="pages > 1"
            :class="{ 'pagination--ellipsis': ellipsis }">
            <li class="page-item page-item--active" v-if="currentPage > 1">
                <a class="page-link"
                   :href="getHref(1)"
                   @click="setPage($event, 1)"
                   aria-label="First">
                    <span aria-hidden="true">First</span>
                    <span class="sr-only">First Page</span>
                </a>
            </li>
            <li class="page-item page-item--active" v-if="ellipsis && currentPage > 2">
                <a class="page-link"
                   :href="getHref(currentPage - ellipsisThreshold)"
                   @click="setPage($event, currentPage - ellipsisThreshold)">...</a>
            </li>
            <li v-for="i in pages"
                class="page-item"
                :class="{'page-item--active' : !ellipsis || ellipsisRange.includes(i)}">
                <a class="page-link"
                   :class="{ 'page-link--current': i === currentPage }"
                   :href="getHref(i)"
                   @click="setPage($event, i)">{{ i }}</a>
            </li>
            <li class="page-item page-item--active" v-if="ellipsis && currentPage < pages - 1">
                <a class="page-link"
                   :href="getHref(currentPage + ellipsisThreshold)"
                   @click="setPage($event, currentPage + ellipsisThreshold)">...</a>
            </li>
            <li class="page-item page-item--active" v-if="currentPage < pages">
                <a class="page-link"
                   aria-label="Last"
                   :href="getHref(pages)"
                   @click="setPage($event, pages)">
                    <span aria-hidden="true">Last</span>
                    <span class="sr-only">Last Page</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<style lang="scss">
    .page-link{
        &--current{
            background: #e6e6e6;
        }
    }
</style>

<script type="text/javascript">
    export default {
        name: 'Pagination',
        model: {
            prop: 'value',
            event: 'change',
        },
        props: {
            total: {
                type: [Number, String],
                required: true,
            },
            perPage: {
                type: [Number, String],
                default: 25,
                validator: v => v > 0,
            },
            value: {
                type: [Number, String],
                default: 1,
            },
            ellipsis: {
                type: Boolean,
                default: true,
            },
            ellipsisThreshold: {
                type: Number,
                default: 3,
            },
            linkTemplate: {
                type: String,
                default: '#page-%page%',
            },
        },
        data() {
            return {
                currentPage: Number(this.value),
            };
        },
        watch: {
            value(newpage) {
                this.currentPage = newpage;
            }
        },
        methods: {
            setPage(e, p) {
                if (p !== this.currentPage) {
                    this.currentPage = this.getSantizedPage(p);
                    this.$emit('change', this.currentPage);
                }
                this.$emit('click', e, this.currentPage);
            },
            getHref(p) {
                return this.linkTemplate.replace('%page%', p);
            },
            getSantizedPage(p) {
                if (p < 1) {
                    return 1;
                }
                if (p > this.pages) {
                    return this.pages;
                }
                return p;
            }
        },
        computed: {
            pages() {
                return Math.ceil(Number(this.total) / Number(this.perPage));
            },
            ellipsisRange() {
                const chunk = [...new Array(this.ellipsisThreshold).keys()];
                while (chunk.length > this.pages) {
                    chunk.pop();
                }

                const range = chunk.map(i => i + this.currentPage - Math.floor(this.ellipsisThreshold / 2));

                if (range[0] < 1) {
                    return chunk.map(i => i + 1); // First Chunk
                }
                if (range.slice(-1).pop() >= this.pages) {
                    return chunk.reverse().map(i => this.pages - i);  // Last Chunk
                }
                return range;
            }
        },
    };
</script>