<template>
    <div class="m-api">
        <div class="m-api__result">
            <label for="itemSearch" style="margin-bottom: 4px; font-size: 1rem;">Search {{ pagetypedisplayname }}</label>
            <div class="m-search">
                <input v-model.lazy="searchVal" name="itemSearch"  style="height: 48px;" placeholder="Enter your search" v-debounce="500" @change="onChange" id="itemSearch" type="text" />
                <button type="submit" :aria-label="'Perform ' + pagetypedisplayname + ' search'" class="m-search__btn btn btn--green">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </button>
            </div>

            <slot :result="result" :noresult="noresult" :loading="loading" :error="error" :searchVal="searchVal"></slot>
        </div>
    </div>
</template>

<script>
    import debounce from 'v-debounce';
    import $ from 'jquery';
    export default {
        name: 'SearchFilter',
        props: {
            endpoint: String,
            pagetype: String,
            pagetypedisplayname: String,
            auth: String,
        },
        data() {
            return {
                searchVal: '',
                loading: false,
                result: null,
                error: null,
                noresult: null,
            };
        },
        directives: {
            debounce,
        },
        methods: {
            onChange() {
                if (this.searchVal != "") {
                    var url = "";

                    if (this.searchVal != "" || this.searchVal != undefined) {
                        url = this.endpoint + "?q=" + this.searchVal;
                    } else {
                        url = this.endpoint + "?q=";
                    }

                    $.ajax({
                        url: url,
                        type: 'GET',
                        dataType: 'json',
                        beforeSend: (xhr) => {
                            xhr.setRequestHeader('Authorization', 'Basic ' + this.auth);
                            this.loading = true;
                            this.noresult = false;
                        },
                        success: (r) => {
                            this.$emit('success', r);
                            this.result = r;
                            if (r.length == 0) {
                                this.loading = false;
                                this.noresult = true;
                            }

                            return this.data;
                        },
                        error: (e) => {
                            this.$emit('error', e);
                            this.error = e;
                        }

                    }).done(() => {
                        this.loading = false;
                    });
                }
            },
        },
        mounted() {

        },
    };
</script>