export default class GoogleLoader {
    constructor(key, libraries = [], cb = 'googleMapsLoaderInit') {
        this.key = key;
        this.libraries = libraries.join(',');
        this.cb = cb;
    }

    load(async = true, defer = true) {
        return new Promise((resolve) => {
            if (!document.getElementById('google-maps-api-js')) {
                const script = Object.assign(document.createElement('script'), {
                    id: 'google-maps-api-js',
                    type: 'text/javascript',
                    src: `https://maps.googleapis.com/maps/api/js?key=${this.key}&callback=${this.cb}&libraries=${this.libraries}`,
                    async,
                    defer,
                });
                document.body.appendChild(script);
            }
            
            window[this.cb] = resolve;
        }).then(() => window.google);
    }
}
