var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-api" }, [
    _c(
      "div",
      { staticClass: "m-api__result" },
      [
        _c(
          "label",
          {
            staticStyle: { "margin-bottom": "4px", "font-size": "1rem" },
            attrs: { for: "itemSearch" }
          },
          [_vm._v("Search " + _vm._s(_vm.pagetypedisplayname))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "m-search" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.lazy",
                value: _vm.searchVal,
                expression: "searchVal",
                modifiers: { lazy: true }
              },
              {
                name: "debounce",
                rawName: "v-debounce",
                value: 500,
                expression: "500"
              }
            ],
            staticStyle: { height: "48px" },
            attrs: {
              name: "itemSearch",
              placeholder: "Enter your search",
              id: "itemSearch",
              type: "text"
            },
            domProps: { value: _vm.searchVal },
            on: {
              change: [
                function($event) {
                  _vm.searchVal = $event.target.value
                },
                _vm.onChange
              ]
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "m-search__btn btn btn--green",
              attrs: {
                type: "submit",
                "aria-label": "Perform " + _vm.pagetypedisplayname + " search"
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-search",
                attrs: { "aria-hidden": "true" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _vm._t("default", null, {
          result: _vm.result,
          noresult: _vm.noresult,
          loading: _vm.loading,
          error: _vm.error,
          searchVal: _vm.searchVal
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }