<template>
    <div class="popup" ref="content">
        <div class="show-for-large popup__inner popup__inner--desktop" v-if="currentFeature">
            <slot name="desktop" v-bind:feature="currentFeature"></slot>
        </div>
        <div class="hide-for-large popup__inner popup__inner--mobile" v-if="currentFeature">
            <div class="popup__navigation">
                <a href="#" class="pager pager--down">
                    <i class="fa fa-chevron-left"></i>
                </a>
                <span class="cur-page">{{ page }}</span> / <span class="max-page">{{ getPages().length }}</span>
                <a href="#" class="pager pager--up">
                    <i class="fa fa-chevron-right"></i>
                </a>
            </div>
            <div class="popup__page popup__page--p${page}">
                <slot name="mobile" v-bind:feature="currentFeature" v-bind:page="page"></slot>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        name: 'Popup',
        inject: ['getMap'],
        data() {
            return {
                popup: false,
                currentFeature: null,
                page: 1,
            };
        },
        methods: {
            open(f) {
                this.currentFeature = f;
                if (f) {
                    this.refresh();
                    this.popup.setPosition(f.getGeometry().get());
                    this.popup.open(this.getMap());
                }
            },
            close() {
                this.popup.close();
            },
            refresh() {
                this.$nextTick(() => {
                    this.popup.setContent(this.$refs.content.outerHTML);
                });
            },
            getPages() {
                return new Array(this.currentFeature.getProperty('Id') === 74 ? 3 : 2);
            },
            pageDown() {
                this.page = this.page - 1 > 1 ? this.page - 1 : 1;
            },
            pageUp() {
                this.page = this.page + 1 < this.getPages().length ? this.page + 1 : this.getPages().length;
            },
        },
        mounted() {
            this.popup = new google.maps.InfoWindow({
                pixelOffset: new google.maps.Size(0, -55),
                maxWidth: 300,
            });

            $('body').on('click', '.popup__navigation a', (e) => {
                e.preventDefault();

                if ($(e.currentTarget).is('.pager--up')) {
                    this.pageUp();
                } else {
                    this.pageDown();
                }
                this.refresh();
            });
        }
    }
</script>