var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { attrs: { "aria-label": "Pagination" } }, [
    _vm.pages > 1
      ? _c(
          "ul",
          {
            staticClass: "pagination",
            class: { "pagination--ellipsis": _vm.ellipsis }
          },
          [
            _vm.currentPage > 1
              ? _c("li", { staticClass: "page-item page-item--active" }, [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { href: _vm.getHref(1), "aria-label": "First" },
                      on: {
                        click: function($event) {
                          return _vm.setPage($event, 1)
                        }
                      }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("First")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("First Page")
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.ellipsis && _vm.currentPage > 2
              ? _c("li", { staticClass: "page-item page-item--active" }, [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: {
                        href: _vm.getHref(
                          _vm.currentPage - _vm.ellipsisThreshold
                        )
                      },
                      on: {
                        click: function($event) {
                          return _vm.setPage(
                            $event,
                            _vm.currentPage - _vm.ellipsisThreshold
                          )
                        }
                      }
                    },
                    [_vm._v("...")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.pages, function(i) {
              return _c(
                "li",
                {
                  staticClass: "page-item",
                  class: {
                    "page-item--active":
                      !_vm.ellipsis || _vm.ellipsisRange.includes(i)
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      class: { "page-link--current": i === _vm.currentPage },
                      attrs: { href: _vm.getHref(i) },
                      on: {
                        click: function($event) {
                          return _vm.setPage($event, i)
                        }
                      }
                    },
                    [_vm._v(_vm._s(i))]
                  )
                ]
              )
            }),
            _vm._v(" "),
            _vm.ellipsis && _vm.currentPage < _vm.pages - 1
              ? _c("li", { staticClass: "page-item page-item--active" }, [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: {
                        href: _vm.getHref(
                          _vm.currentPage + _vm.ellipsisThreshold
                        )
                      },
                      on: {
                        click: function($event) {
                          return _vm.setPage(
                            $event,
                            _vm.currentPage + _vm.ellipsisThreshold
                          )
                        }
                      }
                    },
                    [_vm._v("...")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentPage < _vm.pages
              ? _c("li", { staticClass: "page-item page-item--active" }, [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: {
                        "aria-label": "Last",
                        href: _vm.getHref(_vm.pages)
                      },
                      on: {
                        click: function($event) {
                          return _vm.setPage($event, _vm.pages)
                        }
                      }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("Last")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Last Page")
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }