require('jquery-ui/ui/widget');
require('jquery-ui/ui/form-reset-mixin');
require('jquery-ui/ui/keycode');
require('jquery-ui/ui/labels');
require('jquery-ui/ui/widgets/button');
require('jquery-ui/ui/widgets/checkboxradio');
require('jquery-ui/ui/widgets/controlgroup');
require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/button.css');
require('jquery-ui/themes/base/checkboxradio.css');
require('jquery-ui/themes/base/controlgroup.css');