<template>
    <div class="m-api">
        <div class="m-api__loading" v-if="loading">
            <slot name="loading">
                <div class="loadingArea">
                    <p><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></p>
                    <p>Loading Careers</p>
                </div>
            </slot>
        </div>

        <div class="m-api__result" v-if="result && !loading && filters">

            <div class="search-main-wrap">
                <label for="careersSearch"><strong>Search Jobs</strong></label>
                <div class="search-wrap">
                    <input id="careersSearch" name="careersSearch" v-model="searchVal" v-on:keyup.13="jobSearch" class="job-search-input" placeholder="Search By Keyword" type="text" style="max-width: 300px;" />
                    <button type="submit" aria-label="Submit Job Search" class="m-search__btn jobs-search btn btn--green" v-on:click="jobSearch">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="checkbox">
                    <input type="checkbox" id="currentEmployee" name="currentEmployee" v-model="internal" /> <label for="currentEmployee">I already work at Carle</label>
                </div>
                <div class="filter-open" v-bind:class="{ opened: showfilters }">
                    <a href="javascript:void(0);" class="filter-toggle" v-on:click="togglefilters"><strong>Advanced Search </strong></a>
                    <a href="javascript:void(0);" v-on:click="clearfilters"><span class="redtext">Reset Search</span></a>
                </div>


            </div>
            
            <div class="advanced-filter" v-bind:class="{ show: showfilters }">
                <div class="select-wrap a-select" v-for="(filter, i) in filters">
                    <label :for="filter.selectID">{{filter.selectTitle}}</label>
                    <select :name="filter.selectID" :id="filter.selectID" v-model="filterModel[filter.selectID]">
                        <option value="" selected>See All</option>
                        <option v-for="field in filter.fields" :value="field.code">
                            {{field.display}}
                        </option>
                    </select>
                </div>

                <label for="req">Requisition Number</label>
                <input name="req" id="req" type="text" v-model="filterModel['req']" />

                <br /><br />
                <button class="a-btn-main jobsSearchSubmit" v-on:click="jobSearch">FILTER</button>
            </div>

            <div class="a-select sort">
                <label for="sort" style="display: inline-block;">Sort by:</label>
                <select name="sort" id="sort" v-on:change="jobSearch" v-model="sort">
                    <option value="relevancy" selected>Relevancy</option>
                    <option value="location">Location</option>
                    <option value="created">Date Posted</option>
                </select>
            </div>
            <slot :result="result" :noresult="noresult" :loading="loading" :referrer="referrer" :error="error" :searchVal="searchVal"></slot>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery';
    export default {
        name: 'jobsearch',
        props: {
            endpoint: String,
            auth: String,
            searchval: String,
            currentpage: Number,
            filters: Array,
        },
        data() {
            return {
                site: '',
                searchVal: '',
                date: '',
                loading: true,
                result: null,
                error: null,
                showfilters: true,
                internal: false,
                sort: 'relevancy',
                noresult: null,
                referrer: '',
                filterModel: {
                    nurses: '',
                    employmenttype: '',
                    category: '',
                    location: '',
                    entity: '',
                    shift: '',
                    req: '',
                },
            };
        },
        methods: {
            jobSearch(currentpage) {
                var pagenum = 1;
                if (currentpage != null && currentpage > 1) {
                    pagenum = currentpage;
                }
                if (pagenum == 1) {
                    this.$emit("pagereset");
                }

                const params = new URLSearchParams(window.location.search);
                let q = params.get("q");
                let cat = this.filterModel.category;
                let ent = this.filterModel.entity;
                let PFshift = this.filterModel.shift;
                let loc = this.filterModel.location;
                let emptype = this.filterModel.employmenttype;
                let nurse = this.filterModel.nurses;
                let req = this.filterModel.req;
                let date = this.date;
                let sortBy = this.sort;

                if (document.getElementById('currentEmployee').checked) {
                    this.internal = true;
                }

                if (this.internal == true) {
                    $("#currentEmployee").prop("checked", true);
                }

                if (this.searchVal != "" || this.date != "" || this.filterModel.category != "" || this.filterModel.entity != "" || this.filterModel.shift != "" || this.filterModel.location != "" || this.filterModel.employmenttype != "" || this.filterModel.nurses != "" || this.sort != "" || pagenum > 1) {
                    var url = "";

                    if (this.site == undefined || this.site == "") {
                        if (cat != "" || emptype != "" || PFshift != "" || ent != "" || loc != "" || nurse != "") {
                            this.showfilters = true;
                            if (this.searchVal == "*:*") {
                                url = this.endpoint + "?q=";
                            } else {
                                url = this.endpoint + "?q=" + this.searchVal;
                            }
                        } else {
                            url = this.endpoint + "?q=" + this.searchVal;
                        }
                    } else {
                        url = this.endpoint + "?q=";
                    }

                    if (cat != undefined && cat != "") {
                        url = url += "&category=" + cat;
                        this.referrer = "";
                    }

                    if (emptype != undefined && emptype != "") {
                        url = url += "&type=" + emptype;
                    }

                    if (PFshift != undefined && PFshift != "") {
                        url = url += "&shift=" + PFshift;
                    }

                    if (ent != undefined && ent != "") {
                        url = url += "&org=" + ent;
                    }

                    if (loc != undefined && loc != "") {
                        url = url += "&location=" + loc;
                    }

                    if (nurse != undefined && nurse != "") {
                        url = url += "&nursecategory=" + nurse;
                    }

                    if (req != undefined && req != "") {
                        url = url += "&reqid=" + req;
                    }

                    if (date != undefined && date != "") {
                        url = url += "&date=" + date;
                    }

                    if (pagenum > 1) {
                        url = url += "&page=" + pagenum;
                    }

                    if (this.sort != '' && this.sort != null) {
                        url = url += '&sort=' + this.sort;
                    }

                    if (this.internal == true) {
                        url += '&site=internal';
                    } else {
                        url += '&site=external';
                    }

                    $.ajax({
                        url: url,
                        type: 'GET',
                        dataType: 'json',
                        beforeSend: (xhr) => {
                            this.loading = true;
                            this.noresult = false;
                        },
                        success: (r) => {
                            this.$emit('success', r);
                            this.result = r;
                            this.$emit('onsearch', r);

                            return this.data;
                        },
                        error: (e) => {
                            this.$emit('error', e);
                            this.error = e;
                        }

                    }).done(() => {
                        this.loading = false;
                    });
                } else {
                    var url = this.endpoint + "?q=*:*";

                    if (this.sort != '' && this.sort != null) {
                        url = url += '&sort=' + this.sort;
                    }

                    $.ajax({
                        url: url,
                        type: 'GET',
                        dataType: 'json',
                        beforeSend: (xhr) => {
                            this.loading = true;
                            this.noresult = false;
                        },
                        success: (r) => {
                            this.$emit('success', r);
                            this.result = r;
                            this.$emit('onsearch', r);

                            return this.data;
                        },
                        error: (e) => {
                            this.$emit('error', e);
                            this.error = e;
                        }

                    }).done(() => {
                        this.loading = false;
                    });
                }
            }, 
            clearfilters() {
                this.filterModel.category = '';
                this.filterModel.nurses = '';
                this.filterModel.employmenttype = '';
                this.filterModel.location = '';
                this.filterModel.entity = '';
                this.filterModel.shift = '';
                this.filterModel.req = '';
                this.sort = "created";
                this.searchVal = "";

                this.jobSearch();
            },
            togglefilters() {
                this.showfilters = !this.showfilters;
            },
        },
        watch: {
            currentpage(newnum, oldnum) {
                this.jobSearch(newnum);
            }
        },
        async mounted(currentpage) {

            var pagenum = 1;
            if (currentpage != null && currentpage > 1) {
                pagenum = currentpage;
            }
            const params = new URLSearchParams(window.location.search);
            const q = params.get("q");
            const PFsite = params.get("site");
            const department = params.get("dept");
            const cat = params.get("category");
            const loc = params.get("location");
            const PFshift = params.get("shift");
            const ent = params.get("org");
            const nurse = params.get("nursecat");
            const emptype = params.get("type");
            const req = params.get("reqid");

            if (PFsite == "external") {
                this.internal = false;
            } else {
                this.internal = true;

            }

            var url = "";

            if (q != "" && q != undefined) {
                this.searchVal = q;
                url = this.endpoint + "?q=" + q;
            } else {
                url = this.endpoint + "?q=";
            }

            if (PFsite != undefined && PFsite != "") {
                url = url += "&site=" + PFsite;
            } 

            if (department != undefined && department != "") {
                url = url += "&dept=" + department;
            }

            if (cat != undefined && cat != "") {
                this.showfilters = true;
                this.filterModel.category = cat;
                url = url += "&category=" + cat;
            }

            if (emptype != undefined && emptype != "") {
                this.filterModel.type = emptype;
                url = url += "&type=" + emptype;
            }

            if (PFshift != undefined && PFshift != "") {
                this.filterModel.shift = PFshift;
                url = url += "&shift=" + PFshift;
            }

            if (ent != undefined && ent != "") {
                url = url += "&org=" + ent;
            }

            if (loc != undefined && loc != "") {
                url = url += "&location=" + loc;
            }

            if (nurse != undefined && nurse != "") {
                url = url += "&nursecategory=" + nurse;
            }

            if (req != undefined && req != "") {
                url = url += "&reqid=" + req;
            }

            if (pagenum > 1) {
                url = url += "&page=" + pagenum;
            }

            url += '&sort=created';

            if ($(window).width() < 768) {
                this.showfilters = false;
            }

            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                beforeSend: (xhr) => {
                    this.loading = true;
                    this.noresult = false;
                },
                success: (r) => {
                    this.$emit('success', r);
                    this.result = r;
                    if (!!params.get("referrer")) {
                        this.result.referrer = params.get("referrer");
                    }
                    this.$emit('onsearch', r);

                    return this.data;
                },
                error: (e) => {
                    this.$emit('error', e);
                    this.error = e;
                }

            }).done(() => {
                this.loading = false;
            });
        },
    };
</script>